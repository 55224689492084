'use strict';

// Imports.
import { mintService } from '../../services';

// Specified actions for mutating the alert state.
export default {
  // Use the mint service to get a list of available shop items.
  async getShopItems({ dispatch, commit }, shopAddress) {
    try {
      let response = await mintService.getShopItems(shopAddress);
      commit('updateShop', response);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  // Use the mint service to get all of a user's frens.
  async getFrens({ dispatch, commit }, { address, collectionAddress, groupId }) {
    try {
      let response = await mintService.getOwnedItems(address, collectionAddress, groupId);
      commit('updateFrens', response);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  // Purchase an item from the mint shop.
  async purchaseItem(
    { dispatch, commit },
    { shopAddress, poolId, groupId, assetId, amount }
  ) {
    try {
      await mintService.purchaseItem(
        shopAddress,
        poolId,
        groupId,
        assetId,
        amount,
        dispatch
      );

      // Catch and notify the user of a purchase error.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  }
};
