<template>
  <div class="nftCard" :style="isClaimed ? { opacity: 0.4 } : {}">
    <div class="imageWrapper">
      <img
        v-if="loaded"
        v-onload="fren.metadata.image"
        alt="NFT Card Image"
        class="image"
        :class="isClaimed ? 'claimed' : ''"
        @load="onImgLoad"
        ref="image"
      />
      <Skeletor v-else width="300px" height="250px" />
    </div>
    <div class="infoWrapper">
      <div class="row">
        <div class="rowLeft">
          <div class="name">
            {{ fren.metadata.name }}
            <span v-if="isClaimed">{{ claimedMessage }}</span>
          </div>
          <span v-if="fren.metadata.price">Ξ {{ fren.metadata.price }}</span>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { preload } from 'vue-onload';

export default {
  props: {
    fren: Object,
    isClaimed: Boolean,
    isIronBull: Boolean
  },

  setup(props) {
    const image = ref(null);
    const loaded = ref(false);
    const store = useStore();
    const claimedMessage = computed(() => {
      return props.isIronBull ? '(Iron bull rolled)' : '(claimed)';
    });

    const onImgLoad = () => {
      loaded.value = true;
    };
    const sources = [props.fren.metadata.image];

    // const formatEthers = (number) => {
    //   console.log("store", store)
    //   //return store.ethers.utils.formatEthers(number);
    // };

    onMounted(() => {
      preload(sources, (completed, count) => {
        if (completed === true) {
          loaded.value = true;
        }
      });
    });

    return {
      claimedMessage,
    //  formatEthers,
      onImgLoad,
      loaded,
      image
    };
  }
};
</script>
<style scoped lang="scss">
.nftCard {
  position: relative;
  display: flex;
  flex-direction: column;
  border-style: none;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: #1b2050;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
  transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  max-width: 400px;

  &:hover {
    box-shadow: 0 14px 40px 0 hsla(0, 0%, 100%, 0.2);
  }

  &:active {
    box-shadow: 0 8px 12px 0 hsla(0, 0%, 100%, 0.12);
  }

  .imageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 auto;

    .image {
      width: 100%;
      height: 100%;
      max-height: 340px;
      object-fit: contain;
    }

    .claimed {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
  }

  .infoWrapper {
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    flex: 1;

    .row {
      display: flex;
      margin-bottom: 5px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .rowLeft {
        display: flex;
        flex-direction: column;
        .name {
          margin-right: 10px;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
