<template>
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 8L4.99999 11L10 8L4.99999 0L0 8ZM0 8.99999L4.99999 12L10 8.99999L4.99999 16L0 8.99999Z"
      fill="white"
    />
  </svg>
</template>
