<template>
  <div class="tab-container" :class="{ 'is-active': isSelected }">
    {{ title }}
  </div>
</template>

<script>
export default {
  props: ['title', 'isSelected']
};
</script>

<style lang="scss" scoped>
.tab-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  transition: background-color 200ms ease, color 200ms ease;
  color: white;
  text-align: center;
  font-weight: 600;
  white-space: nowrap;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.2);
    color: black;
  }

  &.is-active {
    box-shadow: inset 0 -3px 0 0 white;
    color: #ffd915;
  }
}
</style>
