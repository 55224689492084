'use strict';

// Imports.
import initializeConfig from '../initialize-config';
import { ethersService } from './index';
import { ethers } from 'ethers';
import axios from 'axios';

// Initialize this service's configuration.
let config;
(async () => {
  config = await initializeConfig();
})();

const checkRedemption = async function(addressOut, tokenId) {
  if (tokenId > 10000) {
    return true;
  }
  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let redeemerContractAddress = config.tokenRedeemer[networkId];
  let redeemerContract = new ethers.Contract(
    redeemerContractAddress,
    config.tokenRedeemerABI,
    provider
  );
  let formattedTokenId = ethers.BigNumber.from(1)
    .shl(128)
    .add(tokenId);
  let redeemer = await redeemerContract.redeemer(addressOut, formattedTokenId);
  return redeemer !== ethers.constants.AddressZero;
};

const checkNotary = async function(tokenId) {
  if (tokenId > 10000) {
    return true;
  }
  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let notaryAddress = config.notaryAddress[networkId];
  let notaryContract = new ethers.Contract(
    notaryAddress,
    config.notaryABI,
    provider
  );
  let formattedTokenId = ethers.BigNumber.from(1)
    .shl(128)
    .add(tokenId);
  console.log(notaryAddress, tokenId);
  let notaryStatus = await notaryContract.signatureStatus(formattedTokenId);
  return notaryStatus !== 1;
};

const reroll = async function(tokenIds, dispatch) {
  if (!config) {
    config = await initializeConfig();
  }
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();

  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let notaryAddress = config.notaryAddress[networkId];
  let notaryContract = new ethers.Contract(
    notaryAddress,
    config.notaryABI,
    provider
  );

  let address = await signer.getAddress();
  let burnTx = await notaryContract.connect(signer).sign(tokenIds);

  burnTx.wait().then(async result => {
    await dispatch('alert/clear', '', { root: true });
    await dispatch(
      'alert/info',
      {
        message: 'Transaction Confirmed',
        duration: 10000
      },
      { root: true }
    );

    // Refresh after the transaction has confirmed.
    const groupId = 1;
    await dispatch(
      'mint/getFrens',
      { address, collectionAddress: itemCollection[0], groupId },
      { root: true }
    );
  });

  await dispatch(
    'alert/info',
    {
      message: 'Transaction Submitted',
      metadata: {
        transaction: burnTx.hash
      },
      duration: 300000
    },
    { root: true }
  );
};

const redeemToken = async function(tokenIds, addressOut, dispatch) {
  if (!config) {
    config = await initializeConfig();
  }
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let redeemerContractAddress = config.tokenRedeemer[networkId];
  let itemCollection = config.itemCollections[networkId];

  // Initialize Redeemer Contract.
  let redeemerContract = new ethers.Contract(
    redeemerContractAddress,
    config.tokenRedeemerABI,
    provider
  );

  // Initialize Item Contract.
  let itemContract = new ethers.Contract(
    itemCollection[0],
    config.itemABI,
    provider
  );

  // What is this?
  let approvalState = await itemContract
    .connect(signer)
    .isApprovedForAll(address, redeemerContractAddress);
  if (!approvalState) {
    let redeemTx = await itemContract
      .connect(signer)
      .setApprovalForAll(redeemerContractAddress, true);
    await redeemTx.wait();
  }

  // Execute Burn for multiple (?)

  let burnTx = await redeemerContract
    .connect(signer)
    .redeemMult(tokenIds, addressOut);

  burnTx.wait().then(async result => {
    // Dispatch Tx Confirmed Alert.
    await dispatch('alert/clear', '', { root: true });
    await dispatch(
      'alert/info',
      {
        message: 'Transaction Confirmed',
        duration: 10000
      },
      { root: true }
    );

    // Refresh after the transaction has confirmed.
    const groupId = 1;
    await dispatch(
      'mint/getFrens',
      { address, collectionAddress: itemCollection[0], groupId },
      { root: true }
    );
    await dispatch('claim/getRedeemedFrens', {}, { root: true });
  });

  // Dispatch Submitted Alert.
  await dispatch(
    'alert/info',
    {
      message: 'Transaction Submitted',
      metadata: {
        transaction: burnTx.hash
      },
      duration: 300000
    },
    { root: true }
  );
};

export const burnService = {
  redeemToken,
  checkRedemption,
  checkNotary,
  reroll
};
