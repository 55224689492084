'use strict';

// Imports.
import { claimService } from '../../services';

// Specified actions for mutating the alert state.
export default {
  async getRedeemedFrens({ dispatch, commit }) {
    try {
      const redeemedFrens = await claimService.getRedeemedFrens();
      commit('updateRedeemedFrens', { redeemedFrens });
      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getRedeemedIronBulls({ dispatch, commit }) {
    try {
      const redeemedBulls = await claimService.getRedeemedIronBulls();
      commit('updateRedeemedFrens', { redeemedBulls });
      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  }
};
