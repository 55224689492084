'use strict';

// Imports.
import initializeConfig from '../initialize-config';
import { ethersService, redisService } from './index';
//import { ethers } from 'ethers';
import axios from 'axios';
//import { OpenSeaPort, Network } from 'opensea-js';
import { Multicall, ContractCallResults, ContractCallContext } from 'ethereum-multicall';

import ssdk, { ethers, opensea, web3, } from 'ssdk';

//import { OrderSide } from 'opensea-js/lib/types.js'
 let OrderSide = {
   Bid:0,
   Sell:1
 }

// Initialize this service's configuration.
let config;
(async () => {
  config = await initializeConfig();
})();

const sleep = function (ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
};

const getSeaport = async function(){
  let web3Provider = await ethersService.getWeb3jsProvider();
  let chainId =  await web3Provider.eth.getChainId();

  let accts = await web3Provider.eth.getAccounts()

  let network = ( chainId == 1 ) ? opensea.Network.Main : opensea.Network.Rinkeby ;
  let seaport = await ssdk.createSeaPort(web3Provider.currentProvider, network, config.openseaAPIKey)
  // const seaport = new opensea.OpenSeaPort(web3Provider.currentProvider, {
  //   networkName: network,
  //   apiKey: config.openseaAPIKey
  // });

  return seaport
}

const getAsset = async function(tokenAddress, tokenId) {
  //console.log('triggered asset', asset);
  // let seaport = await getSeaport();
  // const asset = await seaport.api.getAsset({
  //   tokenAddress: tokenAddress,
  //   tokenId: tokenId
  // });
  let seaport = await getSeaport();
  const asset = await ssdk.getAsset(tokenAddress, tokenId);

  let contractData = await getAssetContract(tokenAddress);
  let slug = contractData.collection.slug;
  let assetCollection = await getAssetCollection(slug);

  let metadata = {
    id: tokenId,
    name: (asset.name) ? asset.name : `# ${asset.tokenId}`,
    image: asset.imageUrl,
    description: asset.description,
    traits: asset.traits,
    slug: slug,
    price: (asset.sellOrders.length > 0) ? ethers.utils.formatEther(asset.sellOrders[0].basePrice.toString()) : 0,
    contractData: contractData,
    collectionData: assetCollection
  }

  let orders = await getOrders(tokenAddress, tokenId);

  console.log('triggered contractData', contractData);
  let fullMetaAsset = {
    //metadataUri: itemMetadataUri,
    metadata: metadata,
    orders: orders,

    //groupId: item.groupId,
    //cap: item.cap,
    //minted: item.minted,
    //prices: itemPrices
  }

  //let orders = await getAssetOrders(tokenAddress, tokenId);

  return [fullMetaAsset];
};

const getAssetsCollection = async function(tokenAddress, tokenId, page) {
  console.log('getAssetsCollection', tokenId, page);
  let limit = 50;
  let offset = (page) ? page * limit : 0;
  //let offset = 0;
  let seaport = await getSeaport();
  let query = {
    asset_contract_address: tokenAddress,
    offset: offset,
    limit: limit,
    order_by: 'sale_price',
    order_direction: 'asc'
  }

  if(tokenId){
    query.token_ids = tokenId;
  }
  console.log("query", query);
  let collectionAssets = await seaport.api.getAssets(query);
  let assets = [];
  console.log("ret assets", collectionAssets);

  if(collectionAssets.assets.length == 0){
    collectionAssets = await seaport.api.getAssets({
      asset_contract_address: tokenAddress,
      offset: offset,
      limit: limit
    });
  }
  for(let asset of collectionAssets.assets) {
    if(asset.sellOrders){
      console.log("SELL", asset.sellOrders[0].basePrice.toString())

      let fullMetaAsset = {
        metadata: {
          id: asset.tokenId,
          name: (asset.name) ? asset.name : `# ${asset.tokenId}`,
          image: asset.imageUrl,
          description: asset.description,
          traits: asset.traits,
          price: ethers.utils.formatEther(asset.sellOrders[0].basePrice.toString())
        },
        orders: asset.sellOrders[0]
      }
      assets.push(fullMetaAsset);
    }
  }

  return assets;
};

const fillOrder = async function (order, accountAddress, dispatch) {
  let seaport = await getSeaport();
  // let web3Provider = await ethersService.getWeb3jsProvider();
  // const accountAddress = web3Provider.eth.accounts[0];
  //
  console.log("ssdk seaport", ssdk.seaport)
  console.log("fillOrder", order, accountAddress)
  const referrerAddress = accountAddress;
  const recipientAddress = accountAddress;


  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  //let address = await signer.getAddress();
  try{
    const transactionHash = await ssdk.seaport.fulfillOrder({ order, accountAddress, recipientAddress, referrerAddress })

    //const tx = await ssdk.atomicMatch(order, accountAddress);
    //const send = await signer.sendTransaction(tx);
    console.log("received payload", tx.data)
    //const transactionHash = await seaport.fulfillOrder({ order: order, accountAddress})
    //console.log("transactionHash", accountAddress)
  } catch( error) {
    await dispatch('alert/error', error, { root: true });
    console.log("error", error)
  }


};

const getWalletAssets = async function ( address, dispatch, _offset, _limit ) {

  // await dispatch('alert/clear', '', { root: true });
  // await dispatch(
  //   'alert/info',
  //   {
  //     message: 'getWalletAssets',
  //     duration: 10000
  //   },
  //   { root: true }
  // );

  if(address.substring(address.length - 4) == '.eth'){
    let provider = await ethersService.getProvider();
    address = await provider.resolveName(address)
  }

  let limit = (_limit) ? _limit : 500;
  let offset = (_offset) ? _offset : 0;
  let remainingData = true;
  let results = [];
  let page_size = offset = 0;
  let remaining;
  while(remainingData){
    offset = offset + page_size
    try {
      let response = await axios.get(`https://deep-index.moralis.io/api/v2/${address}/nft?chain=eth&format=decimal&offset=${offset}&limit=${limit}`, {
        headers: {
          'accept': 'application/json',
          'X-API-Key': `sFbIgxVqJwrW3WdADwbsF8Q7u2YEH3aeiVijutQtkiDuuVVA403fRsYnlDtCCefO`
        }
      })
      for(let order of response.data.result){
        results.push(order);
      }
      page_size = response.data.page_size;
      remaining = response.data.total - offset;
      remainingData = (response.data.page_size < remaining);

      console.log("remainingData", results.length, remainingData, remaining, response.data.total)
    } catch (error) {
      console.log("[ERROR] getWalletAssets", error)
    }

    //remainingData = false;
    sleep(250)
  }

  //console.log('results', results)
  return results;
};

const getUserAssets = async function (userAddress, page, dispatch) {
  let limit = 20;
  let requestedAssets = await getWalletAssets(userAddress, dispatch, limit * page, limit);
  let assets = [];
  for(let asset of requestedAssets) {

    let meta = (asset.metadata) ? JSON.parse(asset.metadata): null;
    console.log("asset", asset)
    if(meta){
      let image = (meta.image.substring(0,7) == 'ipfs://') ? `http://ipfs.io/ipfs/${meta.image.substring(7)}`: meta.image;
      console.log("image", image)
      let fullMetaAsset = {
        contract: asset.token_address,
        metadata: {
          id: asset.token_id,
          name: (asset.name) ? asset.name : `# ${asset.token_id}`,
          image: image,
          description: meta.description,
          traits: meta.attributes,
          // price: ethers.utils.formatEther(asset.sellOrders[0].basePrice.toString())
        }
      //   orders: asset.sellOrders[0]
       }
       console.log("fullAsset", fullMetaAsset)
       assets.push(fullMetaAsset);
    }
  }
  return assets;
}

const getUserOrders = async function (userAddress, page, dispatch) {
  // let ws = await redisService.openConnection();
  //console.log("socket", ws);
  //await ws.send("Hello server!");
  //await redisService.sendMessage();
  //await redisService.closeConnection();
  await dispatch('alert/clear', '', { root: true });
  await dispatch(
    'alert/info',
    {
      message: 'Loading account',
      duration: 10000
    },
    { root: true }
  );
  let walletAssets = await getWalletAssets(userAddress, dispatch, 0, 200);
  console.log("[[[[[  FLAG  ]]]]]")
  let collections = [];
  for(let item of walletAssets){
    if(collections.includes(item.token_address)){
      collections[item.token_address].push(item.token_id)
    }else{
      collections.push(item.token_address)
      collections[item.token_address] = [item.token_id]
    }
  }
  let assetOrders = [];
  let ords;
  let chunk = 10;
  for(let collection of collections){
    let idLength = collections[collection].length;
    let idLooping = true;
    let iteration = 0;
    while(idLooping){
      await sleep(1000);
      let set = collections[collection].slice( iteration * chunk, (iteration * chunk) + chunk)
      //console.log("ids", set)
      try{
        ords = await getAssetsByOrders(collection, set);
        if(ords){
          console.log("collection", ords)
          assetOrders.push(ords);
        }
      } catch (error) {
        console.log("ERROR getUserOrders", error)
      }



      idLength = idLength - chunk;
      idLooping = (chunk < idLength);
      iteration++;
    }

    // ords = await getAssetsByOrders(collection, collections[collection]);
    // if(ords){
    //   console.log("ords", ords)
    //   assetOrders.push(ords);
    // }
    // await sleep(1000);
  }
  return assetOrders;
  console.log("assetOrders", assetOrders);
  //return collections;
}

const getUserOrdersSocket = async function (address, page, offset, limit, dispatch, commit) {

  // console.log('trimmed addy', address);
  // let key = payload.key
  if(address.substring(address.length - 4) == '.eth'){
    //console.log("add", address)
    let provider = await ethersService.getProvider();
    let parsedname = await provider.resolveName(address)
    //console.log("parsedname", parsedname)
    address = parsedname.toLowerCase();
  }
  //
  // payload.key = key;
  // console.log("out.key", payload)

  let payload = {
    key: `wallet_${address}`,
    api: `opensea`,
    schema: `orders`,
    query: `?maker=${address}&bundled=false&include_bundled=false&limit=${limit}&offset=${offset}&order_by=created_date&order_direction=desc`
  };
  //console.log("payload", payload)
  await dispatch('alert/clear', '', { root: true });
  await dispatch(
    'alert/info',
    {
      message: 'Loading account',
      duration: 10000
    },
    { root: true }
  );
  let records = await redisService.hget(payload.key, payload.schema);
  if(!records.result){
    let ws = await redisService.openConnection('wss://forest.throw.workers.dev/ws', dispatch, commit);
    await sleep(700)
    await ws.send(JSON.stringify(payload))
    await sleep(1500)
    await axios.get('https://consumer.throw.workers.dev/process')

    let recordsReturned = false;
    while(!recordsReturned){
      records = await redisService.hget(payload.key, payload.schema);
      if(records.result){
        recordsReturned = true;
      }else{

        await sleep(5000);
        await axios.get('https://consumer.throw.workers.dev/process')
      }

    }

  }

  let parsedResult = JSON.parse(records.result);
  //return parseResult.orders;
  let ords = [];
  for(let order of parsedResult.orders){
    //console.log("order", order)
    let fullMetaAsset = {
      contract: order.asset.asset_contract.address,
      metadata: {
        id: order.asset.token_id,
        qty: order.quantity,
        name: (order.asset.name) ? order.asset.name : `# ${order.asset.token_id}`,
        image: order.asset.image_url,
        description: order.asset.description,
        traits: order.asset.traits,
        price: ethers.utils.formatEther(order.base_price.toString()),
        collectionName: order.asset.collection.name,
        listingTime: order.listing_time,
        expirationTime: order.expiration_time
        //contractData: contractData
      }
    }
    ords.push(fullMetaAsset);
  }

  return ords;
}

const getMultiCallInstance = async function (tryAggregate) {
  let web3 = await ethersService.getWeb3jsProvider();
  const multicall = new Multicall({ web3Instance: web3, tryAggregate: tryAggregate });
  return multicall;
}

const getOrders = async function (tokenAddress, tokenIds) {
  let safeIds = [];
  for(let id of tokenIds){
    if(id > 0){
      safeIds.push(id);
    }
  }

  let seaport = await getSeaport();
  let twoDaysAgo = new Date();
  twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

  const orders = await seaport.api.getOrders({
    asset_contract_address: tokenAddress,
    token_id: safeIds,
    side: 1
    //listed_after: Math.floor(twoDaysAgo / 1000)
  });

  console.log("os_sdk", tokenAddress, safeIds, orders)
  return orders.orders;
};

const getAssetsByOrders = async function (tokenAddress, tokenIds){
  let orders = await getOrders(tokenAddress, tokenIds);
  //let contractData = await getAssetContract(tokenAddress);

  let assets = [];
  let lowestPrice = 100000000;
  let fullMetaAsset;
  for(let order of orders){
    if(ethers.utils.formatEther(order.basePrice.toString()) < lowestPrice){
      lowestPrice = ethers.utils.formatEther(order.basePrice.toString())
      fullMetaAsset = {
        contract: tokenAddress,
        metadata: {
          id: order.asset.tokenId,
          name: (order.asset.name) ? order.asset.name : `# ${order.asset.tokenId}`,
          image: order.asset.imageUrl,
          description: order.asset.description,
          traits: order.asset.traits,
          price: ethers.utils.formatEther(order.basePrice.toString()),
          //contractData: contractData
        }
      }
    }
    //assets.push(fullMetaAsset);
  }
  return fullMetaAsset;
};

const getAssetContract = async function (tokenAddress){
  let seaport = await getSeaport();
  const assetContractData = await seaport.api.get(`/api/v1/asset_contract/${tokenAddress}`, {});
  return assetContractData;
};

const getAssetCollection = async function (slug){
  let seaport = await getSeaport();
  const assetCollectionData = await seaport.api.get(`/api/v1/collection/${slug}`, {});
  return assetCollectionData;
};

const getAssetOrders = async function(tokenAddress, tokenId) {
  let seaport = await getSeaport();
  await sleep(3000);
  let orders = await seaport.api.getOrders({
    asset_contract_address: tokenAddress,
    token_id: tokenId,
    side: '1'
  //}, 2);
  });
  console.log({orders:orders})
  return orders;
};

// Export the sweep service functions.
export const seaportService = {
  getAsset,
  getAssetsCollection,
  getAssetsByOrders,
  getUserAssets,
  getUserOrders,
  fillOrder,
  getOrders,
  getUserOrdersSocket
};
