'use strict';

// Imports.
import { createStore } from 'vuex';

// Import data modules for the store.
import alert from './alert';
import ethers from './ethers';
import visibility from './visibility';
import mint from './mint';
import burn from './burn';
import claim from './claim';
import seaport from './seaport';

// Export the data store using our modules.
export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    alert,
    ethers,
    visibility,
    mint,
    burn,
    claim,
    seaport
  }
});
