'use strict';

// Specified state transitions per particular actions.
export default {

  // Update the list of launchpad items being tracked for display purposes.
  updateShop(state, shop) {
    state.shop = shop;
  },

  updateFrens(state, frens) {
    state.frens = frens;
  }
};
