<template>
  <div class="claim">
    <div class="container" v-if="store.state.ethers.address">
      <div class="topWrapper">
        <div class="executeButton">
          <TextInput v-model="walletAddress" placeholder="Enter a wallet Address or leave blank to use current connected account" class="textInput" />

          <Button @click="socketImport" style="width: 180px;"> Analyze </Button>
          <Button @click="rmImport" style="width: 70px;margin-left:10px"> rm </Button>
        </div>
        <div class="executeButton" v-if="analyzing && frens.length == 0">
          <LoadingSpinnerIcon />
          <div>Analyzing wallet...</div>
        </div>
        <div v-if="!analyzing && finished" style="margin: 20px">
          {{ frens.length }} orders found
          <div v-if="frens.length == 0">
            <br /><br />
            ಠ_ಠ
          </div>
        </div>
      </div>

      <!-- <div class="itemsGrid">
        <div class="item" v-for="fren in frens" :key="fren.id">
          <NFTRow :fren="fren" @click="toggleSelection(fren)" :class="fren.isSelected ? 'selected' : ''" />
          <br />
        </div>
      </div> -->

      <div class="resultsTable" v-if="frens.length > 0">
        <div class="headerWrapper">
          <BaseCheckbox v-model="testCheck" class="title" />
          <div class="title">Image</div>
          <div class="title">Name</div>
          <div class="title">Quantity</div>
          <div class="title">Currency</div>
          <div class="title">Buy It Now Price</div>
          <div class="title">Start Time</div>
          <div class="title">End Time</div>
        </div>

        <NFTRow v-for="fren in frens" :key="fren.id" :nft="fren" />
      </div>


      <div v-if="selectedFrensIds.length > 0" class="burnButtonWrapper">
        <Button v-if="selectedFrensIds.length > 0" @click="claim"> Import {{ selectedFrensIds.length }} </Button>

        <Button v-if="selectedFrensIds.length > 0" @click="claimAll"> Import All </Button>
      </div>
    </div>

    <ConnectWallet v-else />
  </div>
</template>
<script>
'use strict';

// Imports.
import { ref, watch, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { ethers } from 'ethers';

// Component Imports.
import Button from '/src/components/ui/Button.vue';
import TextInput from '/src/components/ui/TextInput.vue';
import BaseCheckbox from '/src/components/ui/BaseCheckbox.vue';
// import NFTRow from '../my-frens/components/NFTRow.vue';

import NFTRow from '/src/pages/import/components/NFTRow.vue';
import LoadingSpinnerIcon from '/src/components/icons/LoadingSpinnerIcon.vue';
import { BigNumber } from '@ethersproject/bignumber';
import ConnectWallet from '/src/components/common/ConnectWallet.vue';
import axios from 'axios';

export default {
  components: {
    Button,
    TextInput,
    NFTRow,
    //  Ethereum,
    ConnectWallet,
    LoadingSpinnerIcon,
    BaseCheckbox
  },

  setup(props, context) {
    const tokenId = ref('');
    const walletAddress = ref('');
    const orderCount = ref(0);
    const itemCount = ref(0);
    const analyzing = ref(false);
    const finished = ref(false);
    const store = useStore();
    const router = useRouter();
    const selected = ref();
    const claimed = ref(false);
    const checked = ref(false);
    const socket = ref({});
    const loading = ref(false);
    if (store.state.mint.frens.length === 0) loading.value = true;
    // Once the state variable changes, the watch is triggered.
    watch(
      () => store.state.mint.frens,
      (selection) => {
        loading.value = false;
      }
    );

    // watch(
    //   () => store.state.ethers.address,
    //   (previous, next) => {
    //     if (previous !== next) initialize();
    //   }
    // );

    onMounted(() => {
      window.scroll(0, 0);
    });

    // Retrieve the the user's owned frens.
    const initialize = async () => {
      const address = store.state.ethers.address;
      const groupId = 1;
      // let astrofrens = '0x71B11Ac923C967CD5998F23F6dae0d779A6ac8Af'; // mainnet bulls
      let astrofrens = '0xFA6a57812Cc73a44621e332a8253A568B249c19d'; // rinkeby bulls
      if (store.state.ethers.canSign) {
        //walletAddress.value = store.state.ethers.address;
        // await store.dispatch(
        //   'seaport/getAssetsCollection',
        //   {
        //     walletAddress: router.currentRoute.value.query.c,
        //     tokenId: null,
        //     page: (router.currentRoute.value.query.p) ? router.currentRoute.value.query.p : 0
        //   },
        //   { root: true }
        // );
        // walletAddress.value = router.currentRoute.value.query.c;
        //getUserAssets
      }
      walletAddress.value = store.state.ethers.address;
    };

    watch((router) => {
      initialize();
    });

    const openSocket = async () => {
      await store.dispatch(
        'seaport/openSocket',
        {},
        { root: true }
      );
      socket.value = store.state.seaport.socket;
    }

    const sendMessage = async () => {
      let address = '0xa0fdca6a36a3019eedc1ed50b88233ea5c6b0016';//store.state.ethers.address;
      let message = {
        key: `wallet_${address}`,
        api: `opensea`,
        schema: `orders`,
        query: `?maker=${address}&bundled=false&include_bundled=false&limit=50&offset=0&order_by=created_date&order_direction=desc`
      };

      await store.dispatch(
        'seaport/sendMessage',
        {
          socket: socket.value,
          message: message
        },
        { root: true }
      );
      return message;
    }

    const closeSocket = async () => {
      await store.dispatch(
        'seaport/closeSocket',
        { socket: socket.value },
        { root: true }
      );
    }

    const startImport = async () => {
      analyzing.value = true;
      let queryAddress = walletAddress.value != '' ? walletAddress.value : store.state.ethers.address;
      //let queryAddress = store.state.ethers.address;
      await store.dispatch(
        'seaport/getUserOrders',
        {
          userAddress: queryAddress,
          page: router.currentRoute.value.query.p ? router.currentRoute.value.query.p : 0
        },
        { root: true }
      );
      analyzing.value = false;
      finished.value = true;
      orderCount.value = frens.value.length;
    };

    const rmImport = async () => {
      let redis = {
        target: 'https://us1-legal-foal-35464.upstash.io',
        init: {
          headers: {
            "Authorization": "Bearer AYqIASQgYTI5YTI2YWItYThmNi00OWUwLWEzNjYtYzBlMTY4MTViOTYxYmY1ZTMzNmY5Y2RlNGM0NjhhNjFlNDFmYmJmNjUwODk="
          },
          method: 'get'
        }
      }
      let key = `wallet_${walletAddress.value}`
      let endpoint = `${redis.target}/HDEL/${key}/orders`;

      let call = await axios.get(endpoint, redis.init)
      // let jsondata = await call.json()
      console.log('redis remove success', call.data.result)

      await store.dispatch(
        'seaport/clearItems',
        {},
        { root: true }
      );
    };

    const socketImport = async () => {
      analyzing.value = true;
      //await openSocket();
      //let address = '0xa0fdca6a36a3019eedc1ed50b88233ea5c6b0016';//store.state.ethers.address;
      let payload = {
        key: `wallet_${walletAddress.value}`,
        api: `opensea`,
        schema: `orders`,
        query: `?maker=${walletAddress.value}&bundled=false&include_bundled=false&limit=50&offset=0&order_by=created_date&order_direction=desc`
      };
      //let payload = await sendMessage();
      //let queryAddress = walletAddress.value != '' ? walletAddress.value : store.state.ethers.address;
      await store.dispatch(
        'seaport/getUserOrdersSocket',
        {
          payload: payload,
          page: router.currentRoute.value.query.p ? router.currentRoute.value.query.p : 0
        },
        { root: true }
      );
      analyzing.value = false;
      finished.value = true;
      orderCount.value = frens.value.length;
      //await closeSocket();
    }

    const fillOrder = async (order) => {
      await store.dispatch(
        'seaport/fillOrder',
        {
          order: order,
          accountAddress: store.state.ethers.address
        },
        { root: true }
      );
    };

    const page = computed(() => {
      // if friend is already claimed, then dont allow it to be clicked
      //return store.state.mint.frens?.slice(0);
      return router.currentRoute.value.query.p ? router.currentRoute.value.query.p : 0;
    });

    const frens = computed(() => {
      // if friend is already claimed, then dont allow it to be clicked
      //return store.state.mint.frens?.slice(0);
      return store.state.seaport.assets?.slice(0);
    });

    const redeemedFrenIds = computed(() => {
      var set = new Set();
      store.state.claim.redeemedFrens.redeemedFrens?.forEach((item) => {
        set.add(item);
      });
      return set;
    });

    const toggleSelection = (fren) => {
      fren.isSelected = !fren.isSelected;
    };

    const selectedFrensIds = computed(() => {
      let frenIds = [];
      for (let fren of frens.value) {
        if (fren.isSelected) frenIds.push(fren.id);
      }
      return frenIds;
    });

    const claim = () => {
      router.push({
        name: 'Confirm Burn',
        params: {
          frens: selectedFrensIds.value
        }
      });
    };

    const claimAll = () => {
      const allFrensIds = [];
      for (let fren of frens.value) {
        const parsedFrenId = parseInt(ethers.BigNumber.from('0x' + fren.id.substring(32).toString()));
        if (!redeemedFrenIds.value.has(parsedFrenId)) {
          allFrensIds.push(fren.id);
        }
      }

      router.push({
        name: 'Confirm Burn',
        params: {
          frens: allFrensIds
        }
      });
    };

    const routerForward = async () => {
      router.push({
        path: 'import',
        query: {
          p: router.currentRoute.value.query.p ? parseInt(router.currentRoute.value.query.p) + 1 : 1
        }
      });
    };

    const routerBackward = async () => {
      router.push({
        path: 'import',
        query: {
          p: router.currentRoute.value.query.p > 0 ? parseInt(router.currentRoute.value.query.p) - 1 : 0
        }
      });
    };

    const checkRedemption = async () => {
      if (!tokenId.value) return;
      // let rickAddress = '0x2c4062D19F8581B377208b3AB6a52EF2f8c75756'; // rinkeby rick
      let rickAddress = '0xc7b9D8483FD01C379a4141B2Ee7c39442172b259'; // mainnet rick
      let check = { address: rickAddress, tokenId: tokenId.value };
      let response = await store.dispatch('burn/checkRedemption', { check }, { root: true });
      checked.value = true;
      claimed.value = response;
    };

    return {
      analyzing,
      finished,
      tokenId,
      walletAddress,
      frens,
      fillOrder,
      page,
      store,
      initialize,
      itemCount,
      orderCount,
      loading,
      selected,
      toggleSelection,
      selectedFrensIds,
      claim,
      claimed,
      checked,
      claimAll,
      checkRedemption,
      startImport,
      redeemedFrenIds,
      routerForward,
      routerBackward,
      openSocket,
      closeSocket,
      sendMessage,
      socketImport,
      rmImport
    };
  }
};
</script>
<style scoped lang="scss">
.item {
  display: block;
}

.textInput {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 570px;
  display: inline-block;
}

.executeButton {
  display: inline-block;
}

.claim {
  padding: 10px 20px;

  .container {
    max-width: 1800px;
    margin-right: auto;
    margin-left: auto;

    .resultsTable {
      margin-bottom: 20px;
      border-style: solid;
      border-width: 1px;
      border-color: rgba(255, 255, 255, 0.2);
      border-radius: 20px;

      .headerWrapper {
        display: grid;
        padding: 10px;
        grid-auto-columns: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 0px;
        grid-template-columns: 50px 80px minmax(200px, 1fr) 100px 150px 150px 250px 250px;
        grid-template-rows: auto;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        font-weight: 600;
        align-items: center;

        .title {
          padding: 10px;
          cursor: pointer;
        }
      }
    }

    .topWrapper {
      display: flex;
      margin-bottom: 40px;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .heading {
        margin-top: 0px;
        margin-bottom: 20px;
        color: #ffd915;
        font-size: 60px;
        line-height: 1em;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
      }

      .subtitle {
        margin-bottom: 10px;
      }

      .claimChecker {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .label {
          white-space: nowrap;
          margin-right: 10px;
        }
      }

      .status {
        margin-top: 10px;
        padding: 10px 20px;
        background-color: #15ff6f;
        color: #000;
        font-weight: 700;
      }

      .claimed {
        background-color: #ff1e1e;
        color: #fff;
      }
    }

    .itemsGrid {
      //display: grid;
      width: 100%;
      justify-content: center;
      justify-items: center;
      grid-auto-columns: 1fr;
      grid-auto-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(300px, min-content));
      grid-template-rows: 1fr;

      .selected {
        box-shadow: 0 0 15px 2px #fb3700;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

    .burnButtonWrapper {
      position: sticky;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 5;
      display: grid;
      min-height: 60px;
      margin-bottom: 40px;
      padding: 60px 30px;
      justify-content: center;
      justify-items: center;
      grid-auto-flow: column;
      grid-auto-columns: auto;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-columns: auto;
      grid-template-rows: auto;
      background-image: linear-gradient(0deg, #000 50%, transparent);
    }
  }
}
</style>
