'use strict';

// Imports.
import { seaportService, redisService } from '../../services';

// Specified actions for mutating the alert state.
export default {
  // Use the mint service to get a list of available shop items.
  async getAsset({ dispatch, commit }, { tokenAddress, tokenId }) {
    try {
      let assets = await seaportService.getAsset( tokenAddress, tokenId );
      console.log("getAsset", assets)
      commit('updateAssets', assets);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getAssetsCollection({ dispatch, commit }, { tokenAddress, tokenId, page }) {
    try {
      let assets = await seaportService.getAssetsCollection( tokenAddress, tokenId, page );
      commit('updateAssets', assets);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getOrders({ dispatch, commit }, { tokenAddress, tokenIds }) {
    try {
      let assets = await seaportService.getAssetsByOrders( tokenAddress, tokenIds );
      commit('updateAssets', [assets]);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async fillOrder({ dispatch, commit }, { order, accountAddress }) {
    try {
      let filled = await seaportService.fillOrder( order, accountAddress, dispatch );
      //commit('updateAssets', assets);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getUserAssets({ dispatch, commit }, { userAddress, page }) {
    try {
      let userAssets = await seaportService.getUserAssets( userAddress, page, dispatch );
      commit('updateAssets', userAssets);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getUserOrders({ dispatch, commit }, { userAddress, page }) {
    try {
      commit('updateAssets', []);
      let userAssets = await seaportService.getUserOrders( userAddress, page, dispatch);
      commit('updateAssets', userAssets);
      await dispatch('alert/clear', '', { root: true });
      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getUserOrdersSocket({ dispatch, commit }, { address, page, offset, limit }) {
    try {
      commit('updateAssets', []);
      let userAssets = await seaportService.getUserOrdersSocket( address, page, offset, limit, dispatch, commit);
      commit('updateAssets', userAssets);
      await dispatch('alert/clear', '', { root: true });
      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async sendMessage({ dispatch, commit }, { socket, message }) {
    try {
      await redisService.sendMessage(socket, message);
    //  commit('updateAssets', userAssets);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async openSocket({ dispatch, commit }, { url }) {
    try {
      let socket = await redisService.openConnection(url, dispatch, commit);
      commit('updateSocket', socket);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async closeSocket({ dispatch, commit }, { socket }) {
    try {
      await redisService.closeConnection(socket);
    //  commit('updateAssets', userAssets);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async clearItems({ dispatch, commit }, { }) {
    try {
      commit('updateAssets', []);
      //let socket = await redisService.clearAsset(url, dispatch, commit);
      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },
};
