'use strict';

// Imports.
import initializeConfig from '../initialize-config';
import { ethersService } from './index';
import { ethers } from 'ethers';
import axios from 'axios';

// Initialize this service's configuration.
let config;
(async () => {
  config = await initializeConfig();
})();

const getRedeemedFrens = async function() {
  if (!config) {
    config = await initializeConfig();
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let signer = await provider.getSigner();
  let address = await signer.getAddress();

  let redeemerContractAddress = config.tokenRedeemer[networkId];

  let redeemerContract = new ethers.Contract(
    redeemerContractAddress,
    config.tokenRedeemerABI,
    signer
  );

  let redeemedEvents = await redeemerContract.queryFilter('TokenRedemption');
  // Index address later.
  const redeemedBullTokenIds = [];
  redeemedEvents.forEach(event => {
    if (event.args[0] === address) {
      // Need to be able to filter out burned bulls vs claimed here.
      let grpId = event.args[3][0].shr(128).toString();
      // let rickAddress = '0x2c4062D19F8581B377208b3AB6a52EF2f8c75756'; // rinkeby rick
      let rickAddress = '0xc7b9D8483FD01C379a4141B2Ee7c39442172b259'; // mainnet rick
      if (grpId === '1' && event.args[2] === rickAddress) {
        const frenId = parseInt('0x' + event.args[1]._hex.substring(32));
        redeemedBullTokenIds.push(frenId);
      }
    }
  });

  return redeemedBullTokenIds;
};

const getRedeemedIronBulls = async function() {
  if (!config) {
    config = await initializeConfig();
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let signer = await provider.getSigner();
  let address = await signer.getAddress();
  // Change to notary address
  let notaryContractAddress = config.notaryAddress[networkId];

  let notaryContract = new ethers.Contract(
    notaryContractAddress,
    config.notaryABI,
    signer
  );

  let redeemedEvents = await notaryContract.queryFilter('Notarize');
  // Index address later.
  const redeemedBullTokenIds = [];
  redeemedEvents.forEach(event => {
    if (event.args[0] === address) {
      event.args[2].forEach(tokenId => {
        const frenId = parseInt('0x' + tokenId._hex.substring(32));
        redeemedBullTokenIds.push(frenId);
      });
    }
  });
  return redeemedBullTokenIds;
};

export const claimService = {
  getRedeemedFrens,
  getRedeemedIronBulls
};
