<template>
  <label>
    <input class="checkboxInput" type="checkbox" :value="model" v-model="model" />
    <span></span>
  </label>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Array,
      required: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  }
};
</script>
<style lang="scss">
:root {
  --checkbox-color: #7112ff;
  --checkmark-color: white;
  label {
    position: relative;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
  span {
    margin-top: 0px;
    margin-right: 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border: 1px solid #000;

    -webkit-transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    cursor: pointer;

    width: 20px;
    height: 20px;
    border-radius: 5px;

    border: 1px solid white;
    display: inline-block;
    transition: all linear 0.3s;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 3px;
      left: 8px;
      border-bottom: 2px solid var(--checkmark-color);
      border-right: 2px solid var(--checkmark-color);
      height: 9px;
      width: 4px;
      transform: rotate(45deg);
      visibility: hidden;
      margin: auto;
    }
  }
  .checkboxInput {
    display: none;
    &:checked ~ span {
      background: var(--checkbox-color);
      border-color: var(--checkbox-color);
      &:after {
        visibility: visible;
      }
    }
  }
}
</style>
