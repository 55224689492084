<template>
  <Ethereum :callback="initialize" />
  <div class="myFrens">
    <div class="container">
      <div class="profileWrapper">
        <div v-if="showAddress" class="address" @click="copyToClipboard">
          {{ formatAddress(address) }}
        </div>
        <div v-else class="address">
          Copied!
        </div>
        <div class="heading">My Frens</div>
        <a
          class="openseaLink"
          href="https://opensea.io/collection/astro-frens"
          target="_blank"
        >
          Click here to view your bulls on opensea
        </a>
        <div class="statWrapper">
          <div class="value">{{ frensCount }}</div>
          <div class="stat">Frens</div>
        </div>
        <div class="tabs">
          <TabItem
            v-for="(tab, index) in tabs"
            :key="index"
            :title="tab"
            :isSelected="currentTab === tab"
            @click="currentTab = tab"
          />
        </div>
      </div>

      <div class="itemsGrid" v-if="loading">
        <SkeletonNFTCard v-for="index in 9" :key="index" />
      </div>

      <div class="itemsGrid" v-if="currentTab === 'Bulls'">
        <NFTCard
          v-for="fren in frens[currentTab]"
          :key="fren"
          :fren="fren"
          @click="navigateToOpensea(fren)"
        />
        <div v-if="frens.length === 0 && !loading">
          No Frens in wallet :(
        </div>
      </div>

      <div class="itemsGrid" v-if="currentTab === 'Burgers'">
        <NFTCard
          v-for="fren in frens[currentTab]"
          :key="fren"
          :fren="fren"
          @click="navigateToOpensea(fren)"
        />
      </div>

      <div class="itemsGrid" v-if="currentTab === 'Rickstro'">
        <NFTCard
          v-for="fren in frens[currentTab]"
          :key="fren"
          :fren="fren"
          @click="navigateToOpensea(fren)"
        />
      </div>
    </div>
  </div>
</template>

<script>
'use strict';

// Imports.
import router from '/src/router';
import { ref, computed, watch, onMounted } from 'vue';
import { formatAddress } from '/src/utility/format';
import { useStore } from 'vuex';
import initializeConfig from '/src/initialize-config';
import { ethers } from 'ethers';
import useClipboard from 'vue-clipboard3';

// Component imports.
import NFTCard from './components/NFTCard.vue';
import SkeletonNFTCard from './components/SkeletonNFTCard.vue';
import Ethereum from '/src/components/common/Ethereum.vue';
import TabItem from '/src/components/ui/TabItem.vue';

export default {
  components: {
    NFTCard,
    SkeletonNFTCard,
    Ethereum,
    TabItem
  },

  data() {
    return {
      formatAddress
    };
  },

  setup() {
    const store = useStore();
    const address = router.currentRoute.value.params.id;
    const showAddress = ref(true);
    const tabs = ['Bulls', 'Rickstro', 'Burgers'];
    const currentTab = ref('Bulls');
    const frens = ref({ Bulls: {}, Rickstro: {}, Burgers: {} });

    let config;

    onMounted(async () => {
      config = await initializeConfig();
    });

    const navigateToOpensea = fren => {
      const tabAddressMapping = {
        Bulls: '0x71B11Ac923C967CD5998F23F6dae0d779A6ac8Af',
        Rickstro: '0xc7b9D8483FD01C379a4141B2Ee7c39442172b259',
        Burgers: '0xa4cffcb247f0f49dc33f9d37513bda70662373ba'
      }; // mainnet
      // const tabAddressMapping = {
      //   Bulls: '0x323F1569fB3e276f6e39Be3008e43150e8467c0d',
      //   Rickstro: '0x2c4062D19F8581B377208b3AB6a52EF2f8c75756'
      // }; // rinkeby
      window.open(
        config.openSeaUrl[store.state.ethers.networkId] +
          tabAddressMapping[currentTab.value] +
          '/' +
          ethers.BigNumber.from(`0x${fren.id}`).toString(),
        '_blank'
      );
    };

    const loading = ref(false);
    if (store.state.mint.frens.length === 0) loading.value = true;
    // Once the state variable changes, the watch is triggered.
    watch(
      () => store.state.mint.frens,
      selection => {
        loading.value = false;
      }
    );

    // Retrieve the the user's owned frens.
    // Imran: this dispatcher will populate store.state.mint.frens correctly but it's not being "awaited". Can't figure out syntax for that, too sleepy.
    const initialize = async () => {
      const groupId = 0; //all frens
      let bullAddress = '0x71B11Ac923C967CD5998F23F6dae0d779A6ac8Af'; // mainnet
      // let bullAddress = '0x323F1569fB3e276f6e39Be3008e43150e8467c0d'; // rinkeby
      await store.dispatch(
        'mint/getFrens',
        { address, collectionAddress: bullAddress, groupId },
        { root: true }
      );
      for (let i = 0; i < store.state.mint.frens.length; i++) {
        frens.value[currentTab.value][i] = store.state.mint.frens[i];
      }
    };

    watch(
      () => currentTab.value,
      async newTab => {
        const tabAddressMapping = {
          Bulls: '0x71B11Ac923C967CD5998F23F6dae0d779A6ac8Af',
          Rickstro: '0xc7b9D8483FD01C379a4141B2Ee7c39442172b259',
          Burgers: '0xa4cffcb247f0f49dc33f9d37513bda70662373ba'
        }; // mainnet

        // const tabAddressMapping = {
        //   Bulls: '0x323F1569fB3e276f6e39Be3008e43150e8467c0d',
        //   Rickstro: '0x2c4062D19F8581B377208b3AB6a52EF2f8c75756'
        // }; // rinkeby

        const groupId = 0;
        await store.dispatch(
          'mint/getFrens',
          { address, collectionAddress: tabAddressMapping[newTab], groupId },
          { root: true }
        );
        for (let i = 0; i < store.state.mint.frens.length; i++) {
          frens.value[newTab][i] = store.state.mint.frens[i];
        }

        console.log(store.state.mint.frens);
      }
    );

    const frensCount = computed(() => {
      return store.state.mint.frens.length;
    });

    const copyToClipboard = async () => {
      const { toClipboard } = useClipboard();
      try {
        await toClipboard(address);
        showAddress.value = false;
        setTimeout(() => {
          showAddress.value = true;
        }, 1000);
      } catch (e) {
        console.error(e);
      }
    };

    return {
      address,
      frens,
      frensCount,
      navigateToOpensea,
      store,
      initialize,
      loading,
      copyToClipboard,
      showAddress,
      tabs,
      currentTab
    };
  }
};
</script>
<style scoped lang="scss">
.myFrens {
  color: white;
  padding: 60px 20px;

  .container {
    width: 1800px;
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;

    .profileWrapper {
      display: flex;
      margin-bottom: 40px;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .address {
        display: block;
        margin-bottom: 20px;
        opacity: 0.5;
        font-size: 20px;
        text-decoration: none;
        cursor: pointer;
        transition: opacity 200ms cubic-bezier(0.215, 0.61, 0.355, 1);

        &:hover {
          opacity: 0.7;
        }

        &:active {
          opacity: 1;
        }
      }

      .heading {
        margin-top: 0px;
        color: #ffd915;
        margin-bottom: 20px;
        font-family: Montserrat, sans-serif;
        font-size: 90px;
        line-height: 1em;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
      }

      .openseaLink {
        transition: opacity 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        color: #fff;
        opacity: 0.6;
        font-size: 20px;
        text-decoration: none;
        cursor: pointer;
        margin-bottom: 20px;
        text-transform: uppercase;

        &:hover {
          opacity: 0.8;
        }

        &:active {
          opacity: 1;
        }
      }

      .statWrapper {
        display: flex;
        font-size: 16px;
        padding: 0px;
        margin-bottom: 20px;

        .value {
          margin-right: 5px;
          font-weight: 700;
        }

        .stat {
          opacity: 0.5;
        }
      }

      .tabs {
        display: flex;
      }
    }

    .itemsGrid {
      display: grid;
      width: 100%;
      grid-auto-columns: 1fr;
      grid-auto-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(300px, min-content));
      grid-template-rows: 1fr;
      justify-content: center;
      justify-items: center;

      .skeletonContainer {
        width: 100%;
      }
    }
  }
}

@media (max-width: 700px) {
  .myFrens .container .profileWrapper .heading {
    font-size: 12vw;
  }
}
</style>
