<template>
  <textarea v-if="isTextArea" :id="id" :placeholder="placeholder" class="textInputField textArea" maxlength="5000" @input="handleInput" />
  <input v-else :placeholder="placeholder" class="textInputField" maxlength="256" type="text" @input="handleInput" :value="modelValue" />
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String
    },

    isTextArea: {
      type: Boolean,
      default: false
    },

    modelValue: {
      type: String
    },

    id: {
      type: String,
      default: ''
    }
  },

  emits: ['update:modelValue'],

  data() {
    return {
      content: this.modelValue
    };
  },

  methods: {
    handleInput(e) {
      this.$emit('update:modelValue', e.target.value);
    }
  }
};
</script>

<style scoped>
.textInputField {
  height: 20px;
  width: 100%;
  padding: 8px 12px;
  line-height: 1.4;
  color: var(--text-color);
  background-color: var(--background-accent);
  border: 1px solid rgba(var(--purple-accent-rgb), 0.2);
  transition: border-color 200ms ease;
  border-radius: 50px;
}

.textInputField:hover {
  border-color: rgba(var(--purple-accent-rgb), 0.5);
}

.textInputField:focus {
  outline: none;
  border-width: 2px;
  border-color: rgba(var(--purple-accent-rgb), 1);
}

.textArea {
  height: auto !important;
}
</style>
