<template>
  <Ethereum :callback="() => {}" />
  <transition name="fade" mode="out-in">
    <div class="wallet-modal-container" v-if="alert.showWalletModal">
      <WalletModal @exit="hideWalletModalDisplay" />
    </div>
  </transition>

  <div class="navBar" :class="navBarStyles">
    <div class="container">
      <div class="logo" @click="routeTo('/')">
      <img src="../../images/lifter-header.png" />
      </div>
      <a v-if="isHome" href="#about" class="link" v-smooth-scroll>About</a>
      <a v-if="isHome" href="#mint" class="link" v-smooth-scroll>Mint</a>
      <a v-if="isHome" href="#roadmap" class="link" v-smooth-scroll>Roadmap</a>
      <a v-if="isHome" href="#faqs" class="link" v-smooth-scroll>FAQs</a>
      <a v-if="isHome" href="#team" class="link" v-smooth-scroll>Team</a>
      <!-- <div
        v-if="isHome"
        href="#benefits"
        class="link"
        v-smooth-scroll
        @click="routeTo('claim')"
      >
        Claim Rickstro Bull
      </div> -->
      <div class="link" @click="openUrl('')">
        <DiscordIcon />
      </div>
      <div class="link" @click="openUrl('')">
        <TwitterIcon />
      </div>

      <div class="menuIcon" @click="showMobileMenu">
        <div class="line"></div>
        <div class="line"></div>
      </div>

      <MobileMenu
        :isVisible="mobileMenuIsVisible"
        :isHome="isHome"
        @toggleVisibility="mobileMenuIsVisible = false"
        @showWalletModal="showWalletModalDisplay"
      />

      <!-- <button
        v-if="ethereum.address"
        class="address"
        :class="addressStyles"
        @click.stop="toggleDropdown"
      >
        <div>{{ profileAddressLabel }}</div>

        <div class="svg-icon">
          <svg
            width="10"
            height="5"
            viewbox="0 0 10 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0L5 5L10 0H0Z" fill="currentColor"></path>
          </svg>
        </div>

        <div class="profile-dropdown" v-if="showDropdown">
          <div
            class="dropdown-item"
            @click="routeTo(`/my-frens/${ethereum.address}`)"
          >
            <div>
              <svg
                width="15"
                height="16"
                viewbox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.5 0.333332H4.5C3.67275 0.333332 3 1.00608 3 1.83333V10.8333C3 11.6606 3.67275 12.3333 4.5 12.3333H13.5C14.3272 12.3333 15 11.6606 15 10.8333V1.83333C15 1.00608 14.3272 0.333332 13.5 0.333332ZM4.5 10.8333V1.83333H13.5L13.5015 10.8333H4.5ZM1.5 4.83333H0V13.8333C0 14.6606 0.67275 15.3333 1.5 15.3333H10.5V13.8333H1.5V4.83333ZM6.75 7.08333L7.5 7.83333L9.75 4.83333L12.75 9.33333H5.25L6.75 7.08333Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
            <div class="item-text">My Frens</div>
          </div>
        </div>
      </button> -->
      <!-- The user is not connected to a signing provider. -->
      <!-- <button
        class="address"
        :class="addressStyles"
        v-if="!ethereum.address && !isMobile"
        @click="showWalletModalDisplay"
      >
        {{ walletPrompt }}
      </button> -->
    </div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { mapState, mapActions } from 'vuex';
// Component imports.
import WalletModal from '../layout/WalletModal.vue';
import MobileMenu from './MobileMenu.vue';

// Icon imports.
import { useTask } from 'vue-concurrency';
import { ethers } from 'ethers';
import initializeConfig from '@/initialize-config';
import store from '@/store';
import Ethereum from '/src/components/common/Ethereum.vue';
import DiscordIcon from '/src/components/icons/DiscordIcon.vue';
import TwitterIcon from '/src/components/icons/TwitterIcon.vue';

// Set up the default header component.
export default {
  name: 'Header',
  components: {
    DiscordIcon,
    TwitterIcon,
    WalletModal,
    MobileMenu,
    Ethereum
  },
  props: {
    isHome: {
      type: Boolean
    }
  },
  data() {
    return {
      showDropdown: false,
      mobileMenuIsVisible: false
    };
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
      ethereum: state => state.ethers
    }),

    walletPrompt() {
      // if (!this.ethereum.provider) {
      //   return '...';
      // }
      return 'Connect Wallet';
    },

    profileAddressLabel() {
      let connectedAddress = this.ethereum.address;
      if (connectedAddress) {
        const shortenedAddress =
          connectedAddress.substring(0, 6) +
          '...' +
          connectedAddress.substring(connectedAddress.length - 4);
        return shortenedAddress;

        // If the user has not connected to a signing address, return no label.
      } else {
        return '';
      }
    },

    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },

    navBarStyles() {
      return this.isHome ? 'homeNavBar' : '';
    },

    addressStyles() {
      return this.isHome ? 'homeAddress' : '';
    },

    logoStyles() {
      return !this.isHome ? { display: 'initial' } : {};
    }
  },

  mounted() {
    document.addEventListener('click', this.close);
  },

  onBeforeUnmount() {
    document.removeEventListener('click', this.close);
  },

  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
      showWalletModal: 'alert/showWalletModal',
      hideWalletModal: 'alert/hideWalletModal'
    }),
    ...mapActions('ethers', ['connectWallet', 'disconnectWallet']),

    // TODO
    showWalletModalDisplay() {
      this.showWalletModal();
    },
    hideWalletModalDisplay() {
      this.hideWalletModal();
    },

    // Connect to an Ethereum wallet.
    connect() {
      this.connectWallet();
    },

    // Disconnect from an Ethereum wallet.
    disconnect() {
      this.disconnectWallet();
    },

    // Toggle the dropdown menu display.
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },

    // Close the dropdown menu.
    close(event) {
      if (!this.$el.contains(event.target)) {
        this.showDropdown = false;
      }
    },

    // Route to a different path.
    routeTo(path) {
      this.$router.push(path);
    },

    openUrl(url) {
      window.open(url, '_blank');
    },

    showMobileMenu() {
      // Disable scrolling on window.
      window.onscroll = function() {
        window.scrollTo(0, 0);
      };
      this.mobileMenuIsVisible = true;
    }
  },

  // Clear the application alert when routes change.
  watch: {
    $route(to, from) {
      // this.clearAlert();
    }
  }
};
</script>

<style scoped lang="scss">
.navBar {
  //background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;

  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .logo {
      margin-right: auto;
      margin-bottom: 0px;
      cursor: pointer;
      transition: all 300ms ease;

      &:active {
        transform: scale(0.95);
      }

      img {
        width: 150px;
      }
    }

    .link {
      display: flex;
      padding: 15px 20px;
      align-items: center;
      border-radius: 40px;
      transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
      color: #fff;
      text-decoration: none;
      font-size: 16px;
      border: none;
      height: fit-content;
      white-space: nowrap;

      &:hover {
        cursor: pointer;
        background-color: hsla(0, 0%, 100%, 0.1);
      }

      &:active {
        background-color: hsla(0, 0%, 100%, 0.2);
      }
    }

    .line {
      border-bottom: 2px solid white;
      width: 100px;
    }

    .menuIcon {
      width: 30px;
      height: 30px;
      grid-template-columns: auto;
      grid-row-gap: 5px;
      color: white;
      padding: 20px;
      border-radius: 50%;
      display: none;

      .line {
        border-bottom: 2px solid white;
        width: 30px;
        height: 10px;
        box-sizing: border-box;
        &:last-child {
          margin-bottom: 10px;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: hsla(0, 0%, 100%, 0.1);
      }

      &:active {
        background-color: hsla(0, 0%, 100%, 0.2);
      }
    }

    .connect {
      box-shadow: inset 0 0 0 1px rgb(128, 128, 128);
    }

    .address {
      right: 20px;
      display: flex;
      padding: 15px 20px;
      align-items: center;
      border-radius: 40px;
      transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
      color: #fff;
      text-decoration: none;
      font-size: 16px;
      background-color: transparent;
      border: none;
      width: fit-content;
      position: relative;
      margin-left: 20px;

      &:hover {
        cursor: pointer;
        background-color: hsla(0, 0%, 100%, 0.1);
      }

      &:active {
        background-color: hsla(0, 0%, 100%, 0.2);
      }

      .svg-icon {
        margin-left: 10px;
      }

      .profile-dropdown {
        position: absolute;
        top: 50px;
        right: 0px;
        width: 140px;
        padding: 10px;
        border-radius: 34px;
        background-color: #1b2050;
        font-size: 14px;
        z-index: 10;

        .dropdown-item {
          padding: 10px;
          padding-left: 15px;
          display: flex;
          align-items: center;
          border-radius: 24px;
          transition: background-color 0.2s ease;

          &:hover {
            cursor: pointer;
            background-color: rgba(255, 255, 255, 0.1);
          }

          .item-text {
            font-size: 16px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.homeNavBar {
  position: absolute;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
}

.wallet-modal-container {
  position: fixed;
  z-index: 9999;
}

.header {
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr repeat(1, auto) 1fr;
  grid-column-gap: 5px;

  padding: 5px 30px;
  justify-items: center;
  position: relative;
  z-index: 99;
}

.header > img {
  height: 40px;
  grid-column-start: 2;
  transition: ease 0.2s;
}

.header > img:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.header > img:active {
  transform: scale(0.95);
}

.wallet_button {
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  border: 2px solid black;
  border-radius: 50px;
  padding: 10px 12px 10px 10px;
  height: 50px;
  width: 190px;
  background: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: ease-in 0.2s;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.wallet_button:hover {
  cursor: pointer;
  background: var(--background-color);
  color: var(--text-color);
}

.wallet_button:active {
  transform: scale(0.9);
}

.profile_button {
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  border: 0px solid black;
  border-radius: 50px;
  padding: 10px 12px 10px 10px;
  height: 40px;
  width: 160px;
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: ease-in 0.2s;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
}

.profile_button:hover {
  cursor: pointer;
  background-color: rgba(var(--purple-accent-rgb), 0.1);
  color: var(--text-color-secondary);
}

.profile_button:active {
  background-color: rgba(var(--purple-accent-rgb), 0.2);
}

.profile_button > p {
  margin-right: 10px;
  margin-left: 10px;
  color: var(--text-color);
  font-size: 14px;
}

.profile-dropdown {
  position: absolute;
  top: 70px;
  right: 10px;
  width: 160px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.2);
  border-radius: 34px;
  background-color: black;
  font-size: 14px;
  z-index: 10;
}

.dropdown-item {
  color: var(--text-color);
  padding: 10px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  cursor: pointer;
  background-color: rgba(var(--purple-accent-rgb), 0.1);
  color: var(--text-color-secondary);
}

.dropdown-item > p {
  margin-left: 10px;
  margin-bottom: 3px;
}

.svg-icon {
  margin-top: -5px;
  margin-left: -5px;
}

.outside {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
}

// Animations
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: all 1s ease;
  transition-delay: 0.5s;
}

.slide-enter-from
// .slide-leave-to
 {
  transform: translateY(-100px);
}

@media (max-width: 1050px) {
  .navBar .container {
  }
  .navBar .container .link {
    display: none;
  }

  .navBar .container .address {
    display: none;
  }

  .navBar .container .menuIcon {
    display: grid;
  }

  .navBar .container .logo {
    display: initial;
    width: fit-content;
    img {
      max-height: 49px;
    }
  }
}
</style>
