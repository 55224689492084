<template>

  <div class="claim">
    <div class="container" v-if="store.state.ethers.address">
      <div class="topWrapper">
        <!--
        <div class="heading">5H0P L1FT3R</div>
        <div class="subtitle">
          collection {{ page }}
        </div>
      -->
      <div class="executeButton">
        <TextInput
          v-model="tokenAddress"
          placeholder="Enter a token Address"
          class="textInput"
        />

        <TextInput
          v-model="tokenId"
          placeholder="Enter a token id"
          class="textInput"
        />
        <Button @click="getCollection" style="width:100px">
          go
        </Button>
      </div>

      <!--
      <div class="executeButton">
        <TextInput
          v-model="offset"
          placeholder="Enter an offset"
          class="textInput"
        />
      </div>
    -->
      <div class="executeButton">

      </div>
        <!--
        <br>
        <TextInput
          v-model="tokenId"
          placeholder="Enter a token Id"
          class="textInput"
        />
      -->
      </div>

      <div class="itemsGrid">
        <div
          class="item"
          v-for="fren in frens"
          :key="fren.id"
        >
          <NFTCard
            :fren="fren"
            @click="toggleSelection(fren)"
            :class="fren.isSelected ? 'selected' : ''"
          />
        <!--
          <Button @click="fillOrder(fren.orders)" style="width:100%; z-index:100">
            Buy for Ξ {{ fren.metadata.price }}
          </Button>
        -->
        </div>
      </div>

      <div class="burnButtonWrapper">
        <Button @click="routerBackward">
          pg back
        </Button>

        <Button @click="routerForward">
          pg forward
        </Button>
      </div>
    </div>

    <ConnectWallet v-else />
  </div>
</template>
<script>
'use strict';

// Imports.
import { ref, watch, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { ethers } from 'ethers';

// Component Imports.
import Button from '/src/components/ui/Button.vue';
import TextInput from '/src/components/ui/TextInput.vue';
import NFTCard from '../my-frens/components/NFTCard.vue';
//import Ethereum from '/src/components/common/Ethereum.vue';
import { BigNumber } from '@ethersproject/bignumber';
import ConnectWallet from '/src/components/common/ConnectWallet.vue';

export default {
  components: {
    Button,
    TextInput,
    NFTCard,
  //  Ethereum,
    ConnectWallet
  },

  setup(props, context) {
    const tokenId = ref('');
    const tokenAddress = ref('');
    const store = useStore();
    const router = useRouter();
    const selected = ref();
    const claimed = ref(false);
    const checked = ref(false);

    const loading = ref(false);
    if (store.state.mint.frens.length === 0) loading.value = true;
    // Once the state variable changes, the watch is triggered.
    watch(
      () => store.state.mint.frens,
      selection => {
        loading.value = false;
      }
    );

    // watch(
    //   () => store.state.ethers.address,
    //   (previous, next) => {
    //     if (previous !== next) initialize();
    //   }
    // );

    onMounted(() => {
      window.scroll(0, 0);
    });

    // Retrieve the the user's owned frens.
    const initialize = async () => {
      const address = store.state.ethers.address;
      const groupId = 1;
      // let astrofrens = '0x71B11Ac923C967CD5998F23F6dae0d779A6ac8Af'; // mainnet bulls
      let astrofrens = '0xFA6a57812Cc73a44621e332a8253A568B249c19d'; // rinkeby bulls
      console.log("address", address)
      if (store.state.ethers.canSign) {
        tokenAddress.value = router.currentRoute.value.query.c;
          // await store.dispatch(
          //   'seaport/getAssetsCollection',
          //   {
          //     tokenAddress: router.currentRoute.value.query.c,
          //     tokenId: null,
          //     page: (router.currentRoute.value.query.p) ? router.currentRoute.value.query.p : 0
          //   },
          //   { root: true }
          // );
          // tokenAddress.value = router.currentRoute.value.query.c;
          //getUserAssets
          await store.dispatch(
            'seaport/getUserAssets', {
              userAddress: address,
              page: (router.currentRoute.value.query.p) ? router.currentRoute.value.query.p : 0
            }, { root: true }
          );

      }
      console.log("init", store.state.ethers.address);

    };

    watch(
      router => {
        console.log("route change")
        initialize();
      }
    );

    const getCollection = async () => {
      console.log("get", router)
      // if token id: then set asset param, if not: set collection param
      //router.replace({ query: {c: tokenAddress.value, p: page.value}})
      let path = tokenId.value ? 'asset' : 'collection';
      let query = tokenId.value ? { c: tokenAddress.value, i: tokenId.value } : { c: tokenAddress.value };
      router.replace({
        path: path,
        query: query
      })

      //router.currentRoute.value.query.c = tokenAddress.value;

      await store.dispatch(
        'seaport/getAssetsCollection',
        {
          tokenAddress: tokenAddress.value,
          tokenId: tokenId.value,
          page: (router.currentRoute.value.query.p) ? router.currentRoute.value.query.p : 0
        },
        { root: true }
      );
    };

    const fillOrder = async (order) => {
      await store.dispatch(
        'seaport/fillOrder',
        {
          order: order,
          accountAddress: store.state.ethers.address
        },
        { root: true }
      );
    };

    const page = computed(() => {
      // if friend is already claimed, then dont allow it to be clicked
      //return store.state.mint.frens?.slice(0);
      console.log("currentRoute", router.currentRoute.value.query.p)
      return (router.currentRoute.value.query.p) ? router.currentRoute.value.query.p : 0;
    });

    const frens = computed(() => {
      // if friend is already claimed, then dont allow it to be clicked
      //return store.state.mint.frens?.slice(0);
      console.log("frens", store.state.seaport.assets);
      return store.state.seaport.assets?.slice(0);
    });

    const redeemedFrenIds = computed(() => {
      var set = new Set();
      store.state.claim.redeemedFrens.redeemedFrens?.forEach(item => {
        set.add(item);
      });
      return set;
    });

    const toggleSelection = fren => {
      //fren.isSelected = !fren.isSelected;
      console.log("toggle", tokenAddress.value, fren)
      router.push({
        path: 'asset',
         query: {
           c: fren.contract,
           i: fren.metadata.id
         }
      });
    };

    const selectedFrensIds = computed(() => {
      let frenIds = [];
      for (let fren of frens.value) {
        if (fren.isSelected) frenIds.push(fren.id);
      }
      return frenIds;
    });

    const claim = () => {
      router.push({
        name: 'Confirm Burn',
        params: {
          frens: selectedFrensIds.value
        }
      });
    };

    const claimAll = () => {
      const allFrensIds = [];
      for (let fren of frens.value) {
        const parsedFrenId = parseInt(
          ethers.BigNumber.from('0x' + fren.id.substring(32).toString())
        );
        if (!redeemedFrenIds.value.has(parsedFrenId)) {
          allFrensIds.push(fren.id);
        }
      }

      router.push({
        name: 'Confirm Burn',
        params: {
          frens: allFrensIds
        }
      });
    };

    const routerForward = async () => {
      console.log("routerForward")
      router.push({
        path: 'wallet',
         query: {
           p: (router.currentRoute.value.query.p) ? parseInt(router.currentRoute.value.query.p) + 1 : 1
         }
      });
    };

    const routerBackward = async () => {
      router.push({
        path: 'wallet',
         query: {
           p: (router.currentRoute.value.query.p > 0) ? parseInt(router.currentRoute.value.query.p) - 1 : 0
         }
      });
    };

    const checkRedemption = async () => {
      if (!tokenId.value) return;
      // let rickAddress = '0x2c4062D19F8581B377208b3AB6a52EF2f8c75756'; // rinkeby rick
      let rickAddress = '0xc7b9D8483FD01C379a4141B2Ee7c39442172b259'; // mainnet rick
      let check = { address: rickAddress, tokenId: tokenId.value };
      let response = await store.dispatch(
        'burn/checkRedemption',
        { check },
        { root: true }
      );
      checked.value = true;
      claimed.value = response;
    };

    return {
      tokenId,
      tokenAddress,
      frens,
      fillOrder,
      page,
      store,
      initialize,
      loading,
      selected,
      toggleSelection,
      selectedFrensIds,
      claim,
      claimed,
      checked,
      claimAll,
      checkRedemption,
      getCollection,
      redeemedFrenIds,
      routerForward,
      routerBackward
    };
  }
};
</script>
<style scoped lang="scss">
.textInput {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 270px;
  display:inline-block;
}

.executeButton {
  display:inline-block;
}

.claim {
  padding: 10px 20px;

  .container {
    width: 1800px;
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;

    .topWrapper {
      display: flex;
      margin-bottom: 40px;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .heading {
        margin-top: 0px;
        margin-bottom: 20px;
        color: #ffd915;
        font-size: 60px;
        line-height: 1em;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
      }

      .subtitle {
        margin-bottom: 10px;
      }

      .claimChecker {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .label {
          white-space: nowrap;
          margin-right: 10px;
        }


      }

      .status {
        margin-top: 10px;
        padding: 10px 20px;
        background-color: #15ff6f;
        color: #000;
        font-weight: 700;
      }

      .claimed {
        background-color: #ff1e1e;
        color: #fff;
      }
    }

    .itemsGrid {
      display: grid;
      width: 100%;
      justify-content: center;
      justify-items: center;
      grid-auto-columns: 1fr;
      grid-auto-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(300px, min-content));
      grid-template-rows: 1fr;

      .selected {
        box-shadow: 0 0 0 4px #15fff3;
      }
    }

    .burnButtonWrapper {
      position: sticky;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 5;
      display: grid;
      min-height: 60px;
      margin-bottom: 40px;
      padding: 60px 30px;
      justify-content: center;
      justify-items: center;
      grid-auto-flow: column;
      grid-auto-columns: auto;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-columns: auto;
      grid-template-rows: auto;
      background-image: linear-gradient(0deg, #000 50%, transparent);
    }
  }
}
</style>
