'use strict';

// Imports.
import initializeConfig from '../initialize-config';
//import { ethersService } from './index';
//import { ethers } from 'ethers';
import axios from 'axios';
//import { createClient } from 'redis';

let config;
(async () => {
  config = await initializeConfig();
})();


// let websocket = new WebSocket(url);
// console.log("websocket", websocket)


const openConnection = async function(url, dispatch, commit) {
  console.log("opening websocket", url)
  //if(!websocket){
  //let url = 'wss://forest.throw.workers.dev/ws';
  let websocket = new WebSocket(url);
    if (!websocket) {
      throw new Error("Server didn't accept WebSocket")
    }
  //}

  console.log("websocket", websocket)
  // websocket.addEventListener("open", () => {
  //   console.log('Opened websocket')
  // })

  // websocket.addEventListener("message", async ({ data }) => {
  //   console.log("message", data)
  // })

  websocket.onopen = function () {
    console.log("opened", websocket)
    websocket.onmessage = function(event) {
      //alert(`[message] Data received from server: ${event.data}`);
      console.log(`[message] Data received from server: ${event.data}`)
      let data = JSON.parse(event.data)
      console.log("status", data.status)
      if(data.status == 'processing'){

      }else if(data.status == 'done'){

        // let records = await hget(data.data, 'orders');
        // console.log("records hget", records)
      }
    };
  }

  websocket.onmessage = function(event) {
    //alert(`[message] Data received from server: ${event.data}`);

    console.log(`[message] Data received from server: ${event.data}`)


    // if(event.data){
    //
    // }
  };

  //
  websocket.onclose = function() {
    console.log('Closed websocket')
  }

  websocket.onerror = function(error) {
    console.log("error", error)
  }
  // websocket.addEventListener("close", message => {
  //   console.log('Closed websocket')
  // })
  //
  // websocket.addEventListener("error", message => {
  //   console.log('Something went wrong with the WebSocket')
  //
  //   // Potentially reconnect the WebSocket connection, by instantiating a
  //   // new WebSocket as seen above, and connecting new events
  //   // websocket = new WebSocket(url)
  //   // websocket.addEventListener(...)
  // })
  return websocket;
}

const sendMessage = async (sock, message) => {
  console.log("sock", sock)
  let send = await sock.send(JSON.stringify(message));
  console.log("send message", send)
}

// Close WebSocket connection at a later point
const closeConnection = async function(sock) {
  console.log("connection closed", sock);
  await sock.close();
}

///

let target = 'https://us1-legal-foal-35464.upstash.io';
let init = {headers: {"Authorization": "Bearer AYqIASQgYTI5YTI2YWItYThmNi00OWUwLWEzNjYtYzBlMTY4MTViOTYxYmY1ZTMzNmY5Y2RlNGM0NjhhNjFlNDFmYmJmNjUwODk="},}

const get = async function( key ) {
  target = `${target}/get/${key}`;
  let response = await fetch(target,init);
  return response;
}

const hget = async function( key, schema ) {
  let endpoint  = `${target}/hget/${key}/${schema}`;
  let response = await axios.get(endpoint,init);
  return response.data;
}


//
// let client = redis.createClient ({
//   host : 'us1-legal-foal-35464.upstash.io',
//   port : '35464',
//   password: 'bf5e336f9cde4c468a61e41fbbf65089'
// });
//
// client.on('connect', function() {
//   console.log('[REDIS] Connected');
// });
//
// const connect = async function() {
//   client = redis.createClient ({
//     host : 'us1-legal-foal-35464.upstash.io',
//     port : '35464',
//     password: 'bf5e336f9cde4c468a61e41fbbf65089'
//   });
// };
//
// const get = async function( property ) {
//   client.get( property, function(err, reply) {
//     console.log('[REDIS]', reply);
//   });
// };
//
// const set = async function( property, value ) {
//   if(!client){
//     connect();
//   }
//   client.set( property, value, function(err, reply) {
//     console.log('[REDIS]', reply);
//   });
// };
//
// const add = async function( list, value ) {
//   if(!client){
//     connect();
//   }
//   client.sadd( list, value, function(err, reply) {
//     console.log('[REDIS] add', reply);
//   });
// }
//
// const publish = async function (property, value){
//   console.log(`[REDIS] Started ${property} channel publisher...`)
//   client.publish(property, value);
// }
//
// const subscribe = async function( property, event ) {
//   client.subscribe( property, function(err, property) {
//     console.log('[REDIS]', `subscribed to ${property}`);
//   });
//   client.on(event, (property, event) => {
//     console.log(`[REDIS] Received message from ${property} channel: ${event}`);
//   });
// };


export const redisService = {
  // get,
  // set,
  // publish,
  // subscribe
  hget,
  openConnection,
  closeConnection,
  sendMessage
};
