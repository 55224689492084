'use strict';

// Component imports.
import { createApp } from 'vue';
import Toaster from '@meforma/vue-toaster';
import VueSmoothScroll from 'vue3-smooth-scroll';
import VueTilt from 'vue-tilt.js';
import AnimatedComponent from '/src/components/utility/AnimatedComponent.vue';
import VueMyToasts from 'vue-my-toasts';
import Toast from '/src/components/common/Toast.vue';
import { Skeletor } from 'vue-skeletor';
import * as onLoad from 'vue-onload';

// Style imports.
import './assets/style/main.scss';
import 'animate.css';
import 'vue-skeletor/dist/vue-skeletor.css';

// Application imports.
import App from './App.vue';
import router from './router';
import store from './store';

import { Directive, DirectiveBinding, VNode } from 'vue';

export const appear = {
  beforeMount(element) {
    element.style.visibility = 'hidden';
  },
  updated(element, binding, node) {
    if (!binding.value === !binding.oldValue || null === node.transition) {
      return;
    }
    if (!binding.value) {
      node.transition.leave(element, () => {
        element.style.visibility = 'hidden';
      });
      return;
    }
    node.transition.beforeEnter(element);
    element.style.visibility = '';
    node.transition.enter(element);
  }
};

// Create our application with required dependencies.
createApp(App)
  .use(router)
  .use(store)
  .use(Toaster)
  .use(VueSmoothScroll, {
    duration: 1500,
    updateHistory: false
  })
  .use(VueMyToasts, {
    // component: BootstrapComponent,
    component: Toast,
    options: {
      width: '50vw',
      position: 'bottom-left',
      padding: '2rem'
    }
  })
  .directive('appear', appear)
  .use(VueTilt)
  .use(onLoad.plugin)
  .component('animated-component', AnimatedComponent)
  .component(Skeletor.name, Skeletor)
  .mount('#app');

store.$app = app;
