'use strict';

// Specified state transitions per particular actions.
export default {

  // Update the list of launchpad items being tracked for display purposes.
  updateAssets(state, assets) {
    state.assets = assets;
  },

  updateCollections(state, collections) {
    state.collections = collections;
  },

  updateUserAssets(state, userAssets) {
    state.userAssets = userAssets;
  },

  updateItemCount(state, itemCount) {
    state.itemCount = itemCount;
  },

  updateCollectionCount(state, collectionCount) {
    state.collectionCount = collectionCount;
  },

  updateOrderCount(state, orderCount) {
    state.orderCount = orderCount;
  },

  updateSocket(state, socket) {
    state.socket = socket;
  },
};
