'use strict';

// Import the Ethers library for some data manipulation tasks.
import { ethers } from 'ethers';

// Import Ethers connection helper functions.
import { ethersService } from '../../services';

// Accessible status messages for future localization.
const MESSAGES = {
  NO_INFURA: `We were unable to connect to the default Infura provider.`,
  UNKNOWN: `We were unable to connect to your Ethereum provider for an unknown reason.`
};

// Export the Ethers actions.
export default {
  // Attempt to initialize our Ethereum provider with event handling.
  // Initialization can be passed a callback which is triggered upon success.
  async initialize({ dispatch, commit }, callback) {
    commit('initializeRequest');
    await ethersService.startProviderWatcher(dispatch, callback);
  },

  // Handle the results of failing to initialize the Ethereum provider.
  async initializeFailure({ dispatch, commit }, errorReason) {
    commit('initializeFailure');
    await dispatch(
      'alert/error',
      {
        message: MESSAGES[errorReason],
        duration: 10000
      },
      { root: true }
    );
  },

  // Handle the results of a successful Ethereum provider initialization.
  async initializeSuccess(
    { commit },
    { hasLocalProvider, canSign, provider, web3Provider, callbacks }
  ) {
    let address;
    if (canSign) {
      let signer = provider.getSigner();
      address = await signer.getAddress();
    }
    let network = await provider.getNetwork();
    let networkId = ethers.utils.hexValue(network.chainId);
    console.log("weeb3", web3Provider)
    commit('initializeSuccess', {
      hasLocalProvider,
      canSign,
      provider,
      web3Provider,
      address,
      networkId
    });

    // Trigger any callbacks attached to our initialization request.
    if (callbacks.length > 0) {
      for (let callback of callbacks) {
        await callback();
      }
    }
  },

  // Pause polling on the provider.
  async pause({ dispatch }) {
    await ethersService.pause(dispatch);
  },

  // Unpause polling on the provider.
  async unpause({ dispatch }) {
    await ethersService.unpause(dispatch);
  },

  // Update the paused status of the provider's polling.
  async changePaused({ commit }, pauseStatus) {
    commit('pausedChanged', pauseStatus);
  },

  // Connect the user's current signing address to the application.
  async connectWallet({}) {
    await ethersService.connectLocalEthereumProvider();
  },

  async connectWCProvider({}) {
    await ethersService.connectWalletConnectProvider();
  },

  // Disconnect the user's current signing address from the application.
  async disconnectWallet({}) {
    await ethersService.disconnectLocalEthereumProvider();
  },

  // Change the currently connected address that we're tracking in VueX state.
  async changeCurrentAddress({ commit }, currentAddress) {
    commit('addressChanged', currentAddress);
  },

  // Change the Ethereum network that we're tracking in VueX state.
  async changeChainId({ commit }, chainId) {
    commit('networkChanged', chainId);
  },

  // Change the block number that we're tracking in VueX state.
  async changeBlockNumber({ commit }, blockNumber) {
    commit('blockNumberChanged', blockNumber);
  }
};
