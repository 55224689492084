<template>
  <div class="notFound">
    <div class="title">
      404 Page not found!
    </div>
    <Button @click="routeTo('/')">
      Return Home
    </Button>
  </div>
</template>
<script>
import { useRouter } from 'vue-router';
import Button from '/src/components/ui/Button.vue';
export default {
  components: {
    Button
  },

  setup() {
    const router = useRouter();
    const routeTo = url => {
      router.push('/');
    };

    return {
      routeTo
    };
  }
};
</script>
<style scoped lang="scss">
.notFound {
  padding: 60px;
  text-align: center;
  .title {
    font-size: 60px;
    font-weight: 800;
    margin-bottom: 40px;
  }
}
</style>
