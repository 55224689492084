// Imports.
import { createRouter, createWebHistory } from 'vue-router';

// Page imports.
import Home from '../pages/home/Home.vue';
import Asset from '../pages/asset/Asset.vue';
import Choke from '../pages/choke/Choke.vue';
import Import from '../pages/import/Import.vue';
import Collection from '../pages/collection/Collection.vue';
import Sweep from '../pages/sweep/Sweep.vue';
import Socket from '../pages/socket/Socket.vue';
import MyFrens from '../pages/my-frens/MyFrens.vue';
import Terms from '../pages/terms/Terms.vue';
import NotFound from '../pages/not-found/NotFound.vue';
import Wallet from '../pages/wallet/Wallet.vue';

// Create routes.
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/asset',
    name: 'Asset',
    component: Asset,
    props: route => ({ ...route.query })
  },
  {
    path: '/choke',
    name: 'Choke',
    component: Choke
  },
  {
    path: '/collection',
    name: 'Collection',
    component: Collection
  },
  {
    path: '/import',
    name: 'Import',
    component: Import
  },
  {
    path: '/socket',
    name: 'Socket',
    component: Socket
  },
  {
    path: '/sweep',
    name: 'Sweep',
    component: Sweep
  },
  {
    path: '/my-frens/:id',
    name: 'My Frens',
    component: MyFrens
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: Wallet
  },
  { path: '/:pathMatch(.*)*', component: NotFound }
];

// Set up the Vue router to operate in web history mode.
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
export default router;
