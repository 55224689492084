<template>
  <div class="container">
    <div class="dropdown-toggle" @click.prevent="toggleDropdown">
      <div class="dropdown-text">{{ currentTitle }}</div>
      <ChevronDownIcon class="icon" />
    </div>
    <nav v-if="showDropdown" class="dropdown-menu">
      <div class="dropdown-item" v-for="(currency, index) in currencies" :key="index" @click="selectCurrency(index)">
        {{ currency }}
      </div>
    </nav>
  </div>
</template>

<script>
import ChevronDownIcon from '@/components/icons/ChevronDownIcon.vue';

export default {
  components: {
    ChevronDownIcon
  },

  props: {
    currencies: {
      type: Array,
      default: () => ['']
    }
  },

  emits: ['currencySelected'],

  data() {
    return {
      showDropdown: false,
      currentIndex: 0
    };
  },

  computed: {
    currentTitle() {
      if (this.currencies.length > 0) {
        return this.currencies[this.currentIndex];
      }
      return '';
    }
  },

  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },

    selectCurrency(index) {
      this.currentIndex = index;
      this.showDropdown = false;
      this.$emit('currencySelected', this.currencies[this.currentIndex]);
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.showDropdown = false;
      }
    }
  },

  mounted() {
    document.addEventListener('click', this.close);
  },

  onBeforeUnmount() {
    document.removeEventListener('click', this.close);
  }
};
</script>

<style scoped>
.container {
  position: relative;
  user-select: none;
}

.icon {
}

.dropdown-toggle {
  display: flex;
  height: 20px;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(var(--text-color-rgb), 0.2);
  border-radius: 24px;
  transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.dropdown-toggle:hover {
  background-color: rgba(var(--text-color-rgb), 0.1);
}

.dropdown-toggle:active {
  background-color: rgba(var(--text-color-rgb), 0.2);
}

.dropdown-text {
  margin-right: 10px;
}

.dropdown-menu {
  position: absolute;
  min-width: 100%;
  top: 43px;
  right: 0px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(var(--text-color-rgb), 0.2);
  border-radius: 30px;
  background-color: var(--background-color-nft);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  z-index: 10;
}

.dropdown-item {
  display: flex;
  height: 40px;
  width: 100%;
  margin-left: 15px;
  padding-left: 10px;
  margin-right: 15px;
  /* padding-right: 10px; */
  align-items: center;
  border-radius: 20px;
  transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: rgba(var(--text-color-rgb), 0.1);
}

.dropdown-item:active {
  background-color: rgba(var(--text-color-rgb), 0.2);
}
</style>
