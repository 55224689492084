<template>
  <div class="terms">
    <h1>TERMS & CONDITIONS</h1>
    <div class="section">
      Astro Frens is a collection of digital artworks (NFTs) running on the
      Ethereum network. This website is only an interface allowing participants
      to exchange digital collectibles. Users are entirely responsible for the
      safety and management of their own private Ethereum wallets and validating
      all transactions and contracts generated by this website before approval.
      Furthermore, as the Astro Frens smart contract runs on the Ethereum
      network, there is no ability to undo, reverse, or restore any
      transactions.
    </div>

    <div class="section">
      This website and its connected services are provided “as is” and “as
      available” without warranty of any kind. By using this website you are
      accepting sole responsibility for any and all transactions involving Astro
      Frens digital collectibles.
    </div>

    <h1>OWNERSHIP</h1>
    <div class="section">
      i. You own the NFT. Each Astro Frens is an NFT on the Ethereum blockchain.
      When you purchase an NFT, you own the underlying Astro Frens, the Art,
      completely. Ownership of the NFT is mediated entirely by the Smart
      Contract and the Ethereum Network: at no point may we seize, freeze, or
      otherwise modify the ownership of any Astro Frens NFT.
    </div>
    <div class="section">
      ii. Personal Use. Subject to your continued compliance with these Terms,
      Astro Frens grants you a worldwide, royalty-free license to use, copy, and
      display the purchased Art, along with any extensions that you choose to
      create or use, solely for the following purposes: (i) for your own
      personal, non-commercial use; (ii) as part of a marketplace that permits
      the purchase and sale of your Astro Frens / NFT, provided that the
      marketplace cryptographically verifies each Astro Frens owner’s rights to
      display the Art for their Astro Frens NFT to ensure that only the actual
      owner can display the Art; or (iii) as part of a third party website or
      application that permits the inclusion, involvement, or participation of
      your Astro Frens, provided that the website/application cryptographically
      verifies each Astro Frens owner’s rights to display the Art for their
      Astro Frens NFT to ensure that only the actual owner can display the Art,
      and provided that the Art is no longer visible once the owner of the Astro
      Frens NFT leaves the website/application.
    </div>

    <div class="section">
      iii. Commercial Use. Subject to your continued compliance with these
      Terms, Astro Frens grants you an unlimited, worldwide license to use,
      copy, and display the purchased Art for the purpose of creating derivative
      works based upon the Art (“Commercial Use”). Examples of such Commercial
      Use would e.g. be the use of the Art to produce and sell merchandise
      products (T-Shirts etc.) displaying copies of the Art. For the sake of
      clarity, nothing in this Section will be deemed to restrict you from (i)
      owning or operating a marketplace that permits the use and sale of Astro
      Frens NFTs generally, provided that the marketplace cryptographically
      verifies each Astro Frens NFT owner’s rights to display the Art for their
      Astro Frens NFT to ensure that only the actual owner can display the Art;
      (ii) owning or operating a third party website or application that permits
      the inclusion, involvement, or participation of Astro Frens generally,
      provided that the third party website or application cryptographically
      verifies each Astro Frens NFT owner’s rights to display the Art for their
      Astro Frens NFT to ensure that only the actual owner can display the Art,
      and provided that the Art is no longer visible once the owner of the
      Purchased Astro Frens NFT leaves the website/application; or (iii) earning
      revenue from any of the foregoing.
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    window.scroll(0, 0);
  }
};
</script>
<style scoped lang="scss">
.terms {
  padding: 60px;
  max-width: 1600px;
  color: white;
  text-align: center;
  h1 {
    font-size: 30px;
    margin-bottom: 30px;
  }

  .section {
    margin-bottom: 20px;
    font-size: 16px;
  }
}
</style>
