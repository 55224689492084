<template>

  <div class="claim">
    <div class="container" v-if="store.state.ethers.address">
      <div class="topWrapper">
        <!--
        <div class="heading">5H0P L1FT3R</div>
        <div class="subtitle">
          collection {{ page }}
        </div>
      -->
      <!--
        <TextInput
          v-model="tokenAddress"
          placeholder="Enter a token Address"
          class="textInput"
        />
        <Button @click="getItem">
          go
        </Button>
      -->
        <!--
        <br>
        <TextInput
          v-model="tokenId"
          placeholder="Enter a token Id"
          class="textInput"
        />
      -->
      </div>

      <div class="itemsGrid">

        <NFTCard
          :fren="fren"
          v-for="fren in frens"
          :key="fren.id"
          :class="fren.isSelected ? 'selected' : ''"
        />
        <div :fren="fren" v-for="fren in frens" :key="fren.id">
          <h2>{{fren.metadata.contractData.name}}</h2>
          <a class="linkColor" :href="fren.metadata.contractData.collection.external_url">{{fren.metadata.contractData.collection.external_url}}</a>

          <p>{{fren.metadata.contractData.description}}</p>
          <Button v-if="orders.length > 0" @click="fillOrder">
            Buy for Ξ {{ fren.metadata.price }}
          </Button>
          </div>
      </div>
      <div class="traitWrapper"
        :fren="fren"
        v-for="fren in frens"
        :key="fren.id"
      >
        <!--<div>{{ fren.metadata.collectionData.collection.stats.count}}</div>-->

        <div
          class="traitBox"
          :trait="trait"
          v-for="trait in fren.metadata.traits"
          :key="trait"
        >
          <div class="traitType">{{trait.trait_type}}</div>
          <div class="traitValue">{{trait.value}}</div>
          <div class="traitRarity">{{((trait.trait_count / fren.metadata.collectionData.collection.stats.count) * 100).toFixed(2)}} % have this trait</div>
        </div>
      </div>

      <div v-if="selectedFrensIds.length > 0" class="burnButtonWrapper">
        <Button v-if="selectedFrensIds.length > 0" @click="claim">
          Burn {{ selectedFrensIds.length }}
        </Button>

        <Button v-if="selectedFrensIds.length > 0" @click="claimAll">
          Burn All
        </Button>
      </div>
    </div>

    <ConnectWallet v-else />
  </div>
</template>
<script>
'use strict';

// Imports.
import { ref, watch, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { ethers } from 'ethers';

// Component Imports.
import Button from '/src/components/ui/Button.vue';
import TextInput from '/src/components/ui/TextInput.vue';
import NFTCard from '../my-frens/components/NFTCard.vue';
//import Ethereum from '/src/components/common/Ethereum.vue';
import { BigNumber } from '@ethersproject/bignumber';
import ConnectWallet from '/src/components/common/ConnectWallet.vue';

export default {
  components: {
    Button,
    //TextInput,
    NFTCard,
  //  Ethereum,
    ConnectWallet
  },

  setup(props, context) {
    const tokenId = ref('');
    const tokenAddress = ref('');
    const orders = ref([]);
    const store = useStore();
    const router = useRouter();
    const selected = ref();
    const claimed = ref(false);
    const checked = ref(false);

    const loading = ref(false);
    if (store.state.mint.frens.length === 0) loading.value = true;
    // Once the state variable changes, the watch is triggered.
    watch(
      () => store.state.mint.frens,
      selection => {
        loading.value = false;
      }
    );

    watch(
      () => store.state.ethers.address,
      (previous, next) => {
        if (previous !== next) initialize();
      }
    );

    onMounted(() => {
      window.scroll(0, 0);
    });

    // Retrieve the the user's owned frens.
    const initialize = async () => {
      const address = store.state.ethers.address;
      const groupId = 1;
      // let astrofrens = '0x71B11Ac923C967CD5998F23F6dae0d779A6ac8Af'; // mainnet bulls
      let astrofrens = '0xFA6a57812Cc73a44621e332a8253A568B249c19d'; // rinkeby bulls
      console.log("router", router.currentRoute.value.query.c);
      if (store.state.ethers.canSign) {
          await store.dispatch(
            'seaport/getAsset',
            {
              tokenAddress: router.currentRoute.value.query.c,
              tokenId: router.currentRoute.value.query.i
              // tokenAddress: '0x18c7766a10df15df8c971f6e8c1d2bba7c7a410b',
              // tokenId: '7105'
            },
            { root: true }
          );
          tokenAddress.value = router.currentRoute.value.query.c;
          tokenId.value = router.currentRoute.value.query.i;
          orders.value = (store.state.seaport.assets.length > 0) ? store.state.seaport.assets[0].orders : [];
          console.log("orders", orders.value)
          //
          // await store.dispatch(
          //   'seaport/getAssetsCollection',
          //   {
          //     tokenAddress: router.currentRoute.value.query.c,
          //     page: page.value
          //   },
          //   { root: true }
          // );
        }


      console.log("init", store.state.ethers.address);

    };


    watch(
      router => {
        console.log("route change")
        initialize();
      }
    );

    const getItem = async () => {
      console.log("get", router)
      router.replace({ query: {c: tokenAddress.value, i: tokenId.value}})
      //router.currentRoute.value.query.c = tokenAddress.value;
      await store.dispatch(
        'seaport/getAsset',
        {
          tokenAddress: tokenAddress.value,
          tokenId: tokenId.value
        },
        { root: true }
      );
    };

    const page = computed(() => {
      // if friend is already claimed, then dont allow it to be clicked
      //return store.state.mint.frens?.slice(0);
      console.log("currentRoute", router.currentRoute.value.query.p)
      return router.currentRoute.value.query.p
    });

    const frens = computed(() => {
      // if friend is already claimed, then dont allow it to be clicked
      //return store.state.mint.frens?.slice(0);
      console.log("frens", store.state.seaport.assets);
      return store.state.seaport.assets?.slice(0);
    });

    const redeemedFrenIds = computed(() => {
      var set = new Set();
      store.state.claim.redeemedFrens.redeemedFrens?.forEach(item => {
        set.add(item);
      });
      return set;
    });

    const toggleSelection = fren => {
      //fren.isSelected = !fren.isSelected;
      router.push({
        name: 'Asset',
         query: {
           c: tokenAddress.value,
           a: fren.id,
           p: page.value
         }
      });
    };

    const selectedFrensIds = computed(() => {
      let frenIds = [];
      for (let fren of frens.value) {
        if (fren.isSelected) frenIds.push(fren.id);
      }
      return frenIds;
    });

    const claim = () => {
      router.push({
        name: 'Confirm Burn',
        params: {
          frens: selectedFrensIds.value
        }
      });
    };

    const claimAll = () => {
      const allFrensIds = [];
      for (let fren of frens.value) {
        const parsedFrenId = parseInt(
          ethers.BigNumber.from('0x' + fren.id.substring(32).toString())
        );
        if (!redeemedFrenIds.value.has(parsedFrenId)) {
          allFrensIds.push(fren.id);
        }
      }

      router.push({
        name: 'Confirm Burn',
        params: {
          frens: allFrensIds
        }
      });
    };

    const fillOrder = async () => {
      await store.dispatch(
        'seaport/fillOrder',
        {
          order: orders.value[0],
          accountAddress: store.state.ethers.address
        },
        { root: true }
      );
    };

    const checkRedemption = async () => {
      if (!tokenId.value) return;
      // let rickAddress = '0x2c4062D19F8581B377208b3AB6a52EF2f8c75756'; // rinkeby rick
      let rickAddress = '0xc7b9D8483FD01C379a4141B2Ee7c39442172b259'; // mainnet rick
      let check = { address: rickAddress, tokenId: tokenId.value };
      let response = await store.dispatch(
        'burn/checkRedemption',
        { check },
        { root: true }
      );
      checked.value = true;
      claimed.value = response;
    };

    return {
      tokenId,
      tokenAddress,
      frens,
      fillOrder,
      orders,
      page,
      router,
      store,
      initialize,
      loading,
      selected,
      toggleSelection,
      selectedFrensIds,
      claim,
      claimed,
      checked,
      claimAll,
      checkRedemption,
      getItem,
      redeemedFrenIds
    };
  }
};
</script>
<style scoped lang="scss">

.linkColor {
  color: #ffd915;
}

.claim {
  padding: 10px 20px;

  .container {
    width: 1800px;
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;

    .topWrapper {
      display: flex;
      margin-bottom: 40px;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .heading {
        margin-top: 0px;
        margin-bottom: 20px;
        color: #ffd915;
        font-size: 60px;
        line-height: 1em;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
      }

      .subtitle {
        margin-bottom: 10px;
      }

      .claimChecker {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .label {
          white-space: nowrap;
          margin-right: 10px;
        }

        .textInput {
          margin-right: 10px;
          margin-bottom: 10px;
          width: 370px;
        }
      }

      .status {
        margin-top: 10px;
        padding: 10px 20px;
        background-color: #15ff6f;
        color: #000;
        font-weight: 700;
      }

      .claimed {
        background-color: #ff1e1e;
        color: #fff;
      }
    }

    .itemsGrid {
      display: grid;
      width: 100%;
      justify-content: center;
      justify-items: center;
      grid-auto-columns: 1fr;
      grid-auto-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(300px, min-content));
      grid-template-rows: 1fr;

      .selected {
        box-shadow: 0 0 0 4px #15fff3;
      }
    }

    .burnButtonWrapper {
      position: sticky;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 5;
      display: grid;
      min-height: 60px;
      margin-bottom: 40px;
      padding: 120px 30px;
      justify-content: center;
      justify-items: center;
      grid-auto-flow: column;
      grid-auto-columns: auto;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-columns: auto;
      grid-template-rows: auto;
      background-image: linear-gradient(0deg, #000 50%, transparent);
    }
  }
  .traitWrapper{
    width:600px;
    margin:20px auto;
  }
  .traitBox {
    border:1px #ffd915 solid;
    width:110px;
    display:inline-block;
    padding:10px 5px;
    min-height:70px;
    vertical-align:top;
    margin:5px 10px;
    text-align:center;
    border-radius:5px;
  }
  .traitType {
    font-weight:bold;
    font-size:.9em;
    color: #ffd915
    //text-shadow: 0px 0px 2px#ffd915
  }
  .traitValue {
    font-weight:bold;
    font-size:.8em;
    margin:10px 0;
  }
  .traitRarity {
    font-size:.6em;
  }
}
</style>
