<template>
  <div class="cardSkeleton">
    <div class="image">
      <Skeletor width="100%" height="100%" />
    </div>
    <div class="row"><Skeletor width="100%" height="100%" /></div>
    <div class="row"><Skeletor width="100%" height="100%" /></div>
  </div>
</template>
<script>
'use strict';
import 'vue-skeletor/dist/vue-skeletor.css';
import { Skeletor } from 'vue-skeletor';

export default {
  components: {
    Skeletor
  }
};
</script>
<style scoped>
.cardSkeleton {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  border-style: none;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: #1b2050;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
  transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
}

.image {
  height: 250px;
  width: 100%;
  margin-bottom: 20px;
}

.row {
  box-sizing: border-box;
  height: 70px;
  width: 100%;
  max-width: 100%;
  padding: 15px 20px;
}
</style>
