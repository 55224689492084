<template>
  <div class="nftRow">
    <BaseCheckbox v-model="isSelected" class="cellWrapper" />
    <div class="cellWrapper">
      <img v-if="loaded" v-onload="nft.metadata.image" alt="NFT Card Image" class="image" @load="onImgLoad" ref="image" />
    </div>

    <div class="nameWrapper">
      <h5>{{ nft.metadata.name }}</h5>
      <h6>{{ nft.metadata.collectionName }}</h6>
      <h6>Token ID: #{{ nft.metadata.id.slice(0, 6) }}...</h6>
    </div>

    <div class="cellWrapper">
      <TextInput v-model="quantity" />
    </div>

    <div class="cellWrapper">
      <CurrencyDropdown :currencies="['ETH', 'USD']" />
    </div>
    <div class="cellWrapper">
      <CurrencyInput v-model="price" :value="modelValue">
        <template #icon>
          <EthIcon class="icon" />
        </template>
      </CurrencyInput>
    </div>
    <div class="cellWrapper">
      <TextInput v-model="startDate" class="dateInput" />
    </div>
    <div class="cellWrapper">
      <TextInput v-model="endtDate" class="dateInput" />
    </div>
  </div>
</template>
<script>
import BaseCheckbox from '/src/components/ui/BaseCheckbox.vue';
import TextInput from './TextInput.vue';
import CurrencyDropdown from './CurrencyDropdown.vue';
import CurrencyInput from './CurrencyInput.vue';
import EthIcon from '/src/components/icons/EthIcon.vue';
import { ref, onMounted, computed } from 'vue';
import { preload } from 'vue-onload';

export default {
  components: {
    BaseCheckbox,
    TextInput,
    CurrencyDropdown,
    CurrencyInput,
    EthIcon
  },

  props: {
    nft: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const image = ref(null);
    const loaded = ref(false);
    const isSelected = ref(false);
    const quantity = ref(props.nft.metadata.qty);
    const price = ref(props.nft.metadata.price);

    // const startDate = ref('12/12/2021 11:00 AM PST');
    // const endDate = ref('12/12/2021 11:00 AM PST');
    let convertedStart = new Date(props.nft.metadata.listingTime * 1000);
    let convertedEnd = new Date(props.nft.metadata.expirationTime * 1000);
    const startDate = ref(convertedStart);
    const endDate = ref(convertedEnd);

    //console.log(props.nft);

    const onImgLoad = () => {
      loaded.value = true;
    };
    const sources = [props.nft.metadata.image];

    onMounted(() => {
      preload(sources, (completed, count) => {
        if (completed === true) {
          loaded.value = true;
        }
      });
    });

    return {
      onImgLoad,
      loaded,
      image,
      isSelected,
      quantity,
      price,
      startDate,
      endDate
    };
  }
};
</script>
<style scoped lang="scss">
.nftRow {
  display: grid;
  padding-right: 10px;
  padding-left: 10px;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
  grid-template-columns: 50px 80px minmax(200px, 1fr) 100px 150px 150px 250px 250px;
  grid-template-rows: auto;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  .cellWrapper {
    padding: 10px;

    .image {
      height: 50px;
      width: 50px;
    }

    .dateInput {
      width: 90%;
    }
  }

  .nameWrapper {
    display: grid;
    padding: 10px;
    grid-auto-columns: 1fr;
    grid-row-gap: 5px;
    grid-template-columns: 1fr;

    h5 {
      font-size: 14px;
      line-height: 1.1;
      font-weight: 500;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    h6 {
      margin-top: 0px;
      margin-bottom: 0px;
      opacity: 0.5;
      font-size: 12px;
      line-height: 1.1;
      font-weight: 400;
    }
  }
}
</style>
